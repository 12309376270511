import hash from 'object-hash';
import intersect from '@turf/intersect';
import react from 'react';
import styled from '@emotion/styled';
import useNavigate from '../../hooks/use-navigate.jsx';
import useRegistry from '../../hooks/use-registry.jsx';
import useSize from '@react-hook/size';
import Victor from 'victor';

import { css } from '@emotion/react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { GameStateContext } from '../../app-contexts.jsx';
import { polygon } from '@turf/helpers';
import { memo, useCallback, useMemo } from 'react';

const DEBUG = false;

const Container = styled.ul`
    list-style-type: none;
    position: relative;
    overflow: hidden;
    pointer-events: none;
`;

const Layer = styled.ul`
    pointer-events: none;
    list-style-type: none;
    height: 100%;
`;

const StationMarker = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(50, 50, 128);
    &:hover {
        border-color: rgb(100, 100, 255);
    }
    pointer-events: auto;
`;

const MapLabel = styled.p`
    background-color: black;
    color: white;
    font-size: 0.75rem;
    padding-left: 3px;
    padding-right: 3px;
    z-index: 10;
    pointer-events: none;
`;

const annotationIcons = {
    ship: {
        Component: styled(FaMapMarkerAlt)``,
        xOffset: -1,
        yOffset: -8,
    },
};

const circleStyles = {
    dotted: {
        Component: DottedCircle,
    },
};

const lineStyles = {
    arrow: {
        Component: TravelArrow,
    },
    travel: {
        Component: TravelLine,
    },
};

const MapDisplay = memo(function MapDisplay({ className, whiteboard }) {
    const { model: gameModel } = react.useContext(GameStateContext);

    const registry = useRegistry('map');

    const navigate = useNavigate(whiteboard, ['corp']);
    const eventHandlers = {
        onStationClick: registry.reduce(
            (accum, { onStationClick } = {}) => onStationClick ?? accum,
            (id) => navigate(`/stations/${id}`),
        ),
    };

    const stations = react.useMemo(
        () =>
            gameModel
                ?.getMatches({ kind: 'station' })
                ?.map((id) => [id, gameModel.get(`/${id}`)]) ?? [],
        [gameModel],
    );

    const containerRef = react.useRef();
    const [containerWidth, containerHeight] = useSize(containerRef);

    // This should change rarely.
    const [toDocument, spanX, spanY] = useViewportProjection(
        stations,
        containerWidth,
        containerHeight,
    );

    // This could change frequently.
    const toViewport = useRelativeWorldProjection(
        { x: spanX / 2, y: spanY / 2 },
        1,
    );

    function screenCoordinates(absWorldCoord) {
        if (!absWorldCoord) {
            return undefined;
        }

        return toDocument(toViewport(absWorldCoord));
    }

    const annotationMap = useMemo(() => {
        const result = {};
        for (const r of registry ?? []) {
            const as = r?.annotations ?? [];

            for (const a of as) {
                a.id = hash(a);
                result[a.id] = a;
            }
        }

        return result;
    }, [registry]);

    const annotations = useMemo(
        () => [...Object.values(annotationMap)],
        [annotationMap],
    );
    const annotationsHash = hash(annotations);

    const [annotationParameters, annotationParametersDispatch] =
        react.useReducer((state, { hash, name, value }) => {
            // Same hash, same value.
            if (state?.[hash]?.[name]) {
                return state;
            }

            return {
                ...state,
                [hash]: {
                    ...state?.[hash],
                    [name]: value,
                },
            };
        }, {});

    // Rendered HTML elements end up here. annotationRefs.current is an
    // <annotation hash> --> { refs: {}, parameters: {} } map, where `refs`
    // is a <slot key> --> HTMLElement map set during render, and `parameters`
    // can be manipulated to adjust future renders.
    const annotationRefs = react.useRef({});

    const canvasRef = react.useRef();
    const c = react.useMemo(
        () => canvasRef?.current?.getContext('2d'),
        [canvasRef.current],
    );

    react.useLayoutEffect(() => {
        // Garbage collect.
        for (const activeKey of Object.keys(annotationRefs.current ?? {})) {
            if (
                !activeKey.includes('_') &&
                !annotations.map(({ id }) => id).includes(activeKey)
            ) {
                delete annotationRefs.current[activeKey];
            }
        }

        performLayout(
            annotationRefs.current,
            annotationParametersDispatch,
            containerWidth,
            containerHeight,
        );

        if (DEBUG && c) {
            c.reset();
            for (const [, note] of Object.entries(
                annotationRefs.current ?? {},
            )) {
                for (const [, slot] of Object.entries(note)) {
                    if (slot.bounds) {
                        c.beginPath();
                        c.strokeStyle = '#000';
                        c.moveTo(slot.bounds[0][0], slot.bounds[0][1]);
                        for (const [x, y] of slot.bounds.slice(1)) {
                            c.lineTo(x, y);
                        }
                        c.lineTo(slot.bounds[0][0], slot.bounds[0][1]);
                        c.stroke();
                    }
                }
            }
        }
    }, [c, gameModel, containerWidth, containerHeight, annotationsHash]);

    return (
        <Container className={className} ref={containerRef}>
            <StationLayer
                eventHandlers={eventHandlers}
                screenCoordinates={screenCoordinates}
                stations={stations}
                annotationRefs={annotationRefs.current}
            />
            <AnnotationLayer
                annotationParameters={annotationParameters}
                annotations={annotations}
                screenCoordinates={screenCoordinates}
                gameModel={gameModel}
                annotationRefs={annotationRefs.current}
            />
            <li>
                <canvas
                    ref={canvasRef}
                    width={containerWidth}
                    height={containerHeight}
                    css={{
                        height: '100%',
                    }}
                />
            </li>
        </Container>
    );
});

export default MapDisplay;

function AnnotationLayer({
    annotationRefs,
    annotationParameters,
    annotations = [],
    gameModel,
    screenCoordinates,
}) {
    function ensureSlot(aHash, slot) {
        if (!annotationRefs[aHash]) {
            annotationRefs[aHash] = {};
        }

        if (!annotationRefs[aHash][slot]) {
            annotationRefs[aHash][slot] = {};
        }

        return annotationRefs[aHash][slot];
    }

    return (
        <li>
            <Layer>
                {annotations.map((a) => {
                    const aHash = a.id;
                    const parameters = annotationParameters?.[aHash] ?? {};

                    if (a?.start) {
                        const start = screenCoordinates(
                            gameModel.get(a.start.$ref),
                        );
                        const end = screenCoordinates(
                            gameModel.get(a.end.$ref),
                        );

                        if (start && end) {
                            const { Component } = lineStyles[a.style];
                            const notes = ensureSlot(aHash, 'line');

                            return (
                                <li key={aHash}>
                                    <Line
                                        hash={aHash}
                                        notes={notes}
                                        start={start}
                                        end={end}
                                    >
                                        <Component {...a.parameters} />
                                    </Line>
                                </li>
                            );
                        }
                    }

                    if (a?.location?.$ref) {
                        const location = screenCoordinates(
                            gameModel.get(a.location.$ref),
                        );

                        if (location && a?.icon) {
                            const { Component, xOffset, yOffset } =
                                annotationIcons[a.icon];

                            return (
                                <li
                                    key={aHash}
                                    css={{
                                        position: 'absolute',
                                        left: `${location.x + xOffset + 1}px`,
                                        top: `${location.y + yOffset + 1}px`,
                                        transform: 'translate(-50%,-50%)',
                                    }}
                                >
                                    <div
                                        ref={noteBounds(
                                            annotationRefs,
                                            {
                                                x: location.x + xOffset + 1,
                                                y: location.y + yOffset + 1,
                                            },
                                            aHash,
                                            'icon',
                                            10,
                                        )}
                                    >
                                        <Component />
                                    </div>
                                </li>
                            );
                        }

                        if (location && a?.label) {
                            const note = ensureSlot(aHash, 'label');
                            note.location = { x: location.x, y: location.y };
                            const offset = {
                                x:
                                    location.x +
                                    (parameters.labelOffset?.x ?? 0),
                                y:
                                    location.y +
                                    (parameters.labelOffset?.y ?? 0),
                            };

                            const indicator =
                                parameters.labelOffset?.axis === 'vertical'
                                    ? {
                                          left: location.x,
                                          width: 0,
                                          top: top(location, offset),
                                          height: height(location, offset),
                                      }
                                    : {
                                          left: left(location, offset),
                                          width: width(location, offset),
                                          top: location.y,
                                          height: 0,
                                      };

                            return (
                                <li key={aHash}>
                                    <div
                                        css={css`
                                            border-width: 1px;
                                            border-style: solid;
                                            border-color: black;
                                            position: absolute;
                                            left: ${indicator.left - 1}px;
                                            top: ${indicator.top - 1}px;
                                            width: ${indicator.width}px;
                                            height: ${indicator.height}px;
                                        `}
                                    />
                                    <MapLabel
                                        ref={noteBounds(
                                            annotationRefs,
                                            offset,
                                            aHash,
                                            'label',
                                            50,
                                        )}
                                        css={{
                                            position: 'absolute',
                                            left: `${
                                                location.x +
                                                (parameters.labelOffset?.x ?? 0)
                                            }px`,
                                            top: `${
                                                location.y +
                                                (parameters.labelOffset?.y ?? 0)
                                            }px`,
                                            transform: 'translate(-50%,-50%)',
                                        }}
                                    >
                                        {a.label}
                                    </MapLabel>
                                </li>
                            );
                        }

                        if (location && a?.radius) {
                            const { Component } = circleStyles[a.style];
                            const rCalc1 = screenCoordinates({ x: 0, y: 0 });
                            const rCalc2 = screenCoordinates({
                                x: a.radius,
                                y: 0,
                            });
                            const dX = rCalc2.x - rCalc1.x;
                            const dY = rCalc2.y - rCalc1.y;
                            const displayRadius = Math.sqrt(dX * dX + dY * dY);

                            return (
                                <li
                                    key={aHash}
                                    css={{
                                        position: 'absolute',
                                        left: `${location.x}px`,
                                        top: `${location.y}px`,
                                        width: `${displayRadius * 2}px`,
                                        height: `${displayRadius * 2}px`,
                                        transform: 'translate(-50%,-50%)',
                                    }}
                                    ref={noteBounds(
                                        annotationRefs,
                                        {
                                            x: location.x - displayRadius,
                                            y: location.y - displayRadius,
                                        },
                                        aHash,
                                        'circle',
                                        0,
                                    )}
                                >
                                    <Component {...a} />
                                </li>
                            );
                        }
                    }
                })}
            </Layer>
        </li>
    );
}

function height(...things) {
    const topmost = top(...things);
    const bottommost = bottom(...things);

    return bottommost - topmost;
}

function width(...things) {
    const leftmost = left(...things);
    const rightmost = right(...things);

    return rightmost - leftmost;
}

function top(...things) {
    let topmost = things[0].y;
    for (const thing of things) {
        if (thing.y < topmost) {
            topmost = thing.y;
        }
    }
    return topmost;
}

function bottom(...things) {
    let bottommost = things[0].y;
    for (const thing of things) {
        if (thing.y > bottommost) {
            bottommost = thing.y;
        }
    }
    return bottommost;
}

function left(...things) {
    let leftmost = things[0].x;
    for (const thing of things) {
        if (thing.x < leftmost) {
            leftmost = thing.x;
        }
    }
    return leftmost;
}

function right(...things) {
    let rightmost = things[0].x;
    for (const thing of things) {
        if (thing.x > rightmost) {
            rightmost = thing.x;
        }
    }
    return rightmost;
}

function StationLayer({
    annotationRefs,
    eventHandlers,
    screenCoordinates,
    stations,
}) {
    return (
        <li>
            <Layer>
                {stations.map(([id, gameData]) => {
                    const location = screenCoordinates(gameData);

                    return (
                        <li
                            key={id}
                            onClick={() => eventHandlers.onStationClick(id)}
                            ref={noteBounds(
                                annotationRefs,
                                location,
                                id,
                                'absoluteIcon',
                                10,
                            )}
                            css={{
                                position: 'absolute',
                                top: `${location.y}px`,
                                left: `${location.x}px`,
                                transform: 'translate(-50%,-50%)',
                            }}
                        >
                            <StationMarker />
                        </li>
                    );
                })}
            </Layer>
        </li>
    );
}

function Line({ notes, children, start, end }) {
    if (!start || !end) {
        return undefined;
    }

    const { x: x1, y: y1 } = start;
    const { x: x2, y: y2 } = end;

    const dX = x2 - x1;
    const dY = y2 - y1;
    const length = Math.sqrt(dX * dX + dY * dY);

    const left = x1;
    const top = y1;

    const angle = Victor(dX, dY).horizontalAngle();
    notes.priority = 20;
    notes.bounds = [
        Victor(0, -1).rotate(angle).toArray(),
        Victor(length, -1).rotate(angle).toArray(),
        Victor(length, 1).rotate(angle).toArray(),
        Victor(0, 1).rotate(angle).toArray(),
    ].map(([x, y]) => [x + left, y + top]);

    return (
        <div
            css={{
                position: 'absolute',
                left: `${left}px`,
                top: `${top}px`,
                width: `${length}px`,
                transformOrigin: 'left',
                transform: `rotate(${angle}rad)`,
            }}
        >
            {children}
        </div>
    );
}

function DottedCircle({ color = '#000' }) {
    return (
        <div
            css={css`
                --borderWidth: 2px;

                height: 100%;
                width: 100%;
                border-color: ${color};
                border-style: dotted;
                border-width: var(--borderWidth);
                border-radius: 50%;
                height: calc(100% - 2 * var(--borderWidth));
                width: calc(100% - 2 * var(--borderWidth));
                pointer-events: none;
            `}
        />
    );
}

function TravelArrow({ color = '#000' }) {
    return (
        <div
            css={css`
                border-bottom: solid 1px ${color};
                position: relative;
                left: 15px;
                top: 4px;
                width: calc(100% - 30px);
            `}
        >
            <div
                css={css`
                    position: absolute;
                    right: 0;
                    top: 1px;
                    height: 6px;
                    width: 12px;
                    background: linear-gradient(
                        to top left,
                        rgb(0, 0, 0, 0) 0%,
                        rgb(0, 0, 0, 0) 50%,
                        ${color} 50%,
                        ${color} 100%
                    );
                `}
            />
        </div>
    );
}

function TravelLine() {
    return (
        <div
            css={(theme) => css`
                background-color: ${theme.colors.text};
                height: 1px;
            `}
        >
            <div
                css={css`
                    background: radial-gradient(
                        rgb(255, 255, 255, 0.5),
                        rgb(0, 255, 255, 0.75) 5%,
                        rgb(0, 0, 255, 0.5) 50%,
                        rgb(0, 0, 0, 0)
                    );
                    position: absolute;
                    top: -1px;
                    height: 3px;
                    border-radius: 50%;
                    animation-duration: 1s;
                    animation-name: pulseTo;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;

                    @keyframes pulseTo {
                        0% {
                            left: 0%;
                            width: 0%;
                        }

                        33% {
                            left: 0%;
                            width: 100%;
                        }

                        66% {
                            left: 50%;
                            width: 50%;
                        }

                        100% {
                            left: 100%;
                            width: 0%;
                        }
                    }
                `}
            />
        </div>
    );
}

function noteBounds(target, { x, y }, key, slot, priority = 0) {
    if (target.current) {
        target = target.current;
    }

    return (r) => {
        if (!target[key]) {
            target[key] = {};
        }

        if (!target[key][slot]) {
            target[key][slot] = {};
        }

        const { width = 0, height = 0 } = r?.getBoundingClientRect() ?? {};

        target[key][slot].rect = {
            x: x - width / 2,
            y: y - height / 2,
            width,
            height,
        };

        target[key][slot].bounds = elementBounds(target[key][slot].rect);
        target[key][slot].priority = priority;
    };
}

function elementBounds(rect) {
    return [
        [rect.x, rect.y],
        [rect.x + rect.width, rect.y],
        [rect.x + rect.width, rect.y + rect.height],
        [rect.x, rect.y + rect.height],
    ];
}

function performLayout(
    annotationElements = {},
    annotationParametersDispatch,
    containerWidth,
    containerHeight,
) {
    const blockers = [];
    for (const [, notes] of Object.entries(annotationElements ?? {})) {
        for (const slot of ['line', 'absoluteIcon']) {
            if (notes[slot]) {
                const { bounds, priority = 10 } = notes[slot];
                blockers.push([bounds, priority]);
            }
        }
    }

    function clampX(left, width) {
        if (left < 0) {
            return 0;
        }

        if (left + width > containerWidth) {
            return containerWidth - width;
        }

        return left;
    }

    for (const [hash, notes] of Object.entries(annotationElements ?? {})) {
        if (notes.label) {
            const {
                x = 0,
                y = 0,
                width = 0,
                height = 0,
            } = notes.label.rect ?? {};

            const [finalPosition, axis] = bestOption(
                notes.label.rect,
                blockers,
                containerWidth,
                containerHeight,
                [
                    [[x, y - 30], 'vertical'],
                    [[x, y - 20], 'vertical'],
                    [[x, y - 10], 'vertical'],
                    [[clampX(x, width), y - 30], 'vertical'],
                    [[x, y + height + 30], 'vertical'],
                    [[x, y + height + 20], 'vertical'],
                    [[x, y + height + 10], 'vertical'],
                    [[clampX(x, width), y + height + 30], 'vertical'],
                    [[x - width / 2 - 20, y], 'horizontal'],
                    [[x + width / 2 + 20, y], 'horizontal'],
                    [[x, y - height / 2], 'vertical'],
                    [[x, y + height / 2], 'vertical'],
                ],
            );

            const [finalX, finalY] = finalPosition;

            annotationParametersDispatch({
                hash,
                name: 'labelOffset',
                value: {
                    x: finalX - x,
                    y: finalY - y,
                    axis,
                },
            });

            blockers.push([
                [
                    [finalX, finalY],
                    [finalX + width, finalY],
                    [finalX + width, finalY + height],
                    [finalX, finalY + height],
                ],
                10,
            ]);
        }
    }
}

function bestOption(rect, blockers, containerWidth, containerHeight, options) {
    try {
        return _bestOption(
            rect,
            blockers,
            containerWidth,
            containerHeight,
            options,
        );
    } catch (e) {
        console.error(
            e,
            'rect',
            rect,
            'blockers',
            blockers,
            'options',
            options,
            'containerWidth',
            containerWidth,
            'containerHeight',
            containerHeight,
        );
        return options[0];
    }
}

function _bestOption(rect, blockers, containerWidth, containerHeight, options) {
    let bestSoFar = options[0];
    let bestPrioritySoFar = 999;

    const { width, height } = rect;
    for (const [[left, top], note] of options) {
        const proposedBounds = [
            [left, top],
            [left + width, top],
            [left + width, top + height],
            [left, top + height],
        ];

        let worstPriority = oob(proposedBounds, containerWidth, containerHeight)
            ? 999
            : 0;
        for (const [blockerBounds, blockerPriority] of blockers) {
            if (
                intersect(
                    polygon([close(blockerBounds)]),
                    polygon([close(proposedBounds)]),
                )
            ) {
                if (blockerPriority > worstPriority) {
                    worstPriority = blockerPriority;
                }
            }
        }

        if (worstPriority < bestPrioritySoFar) {
            bestPrioritySoFar = worstPriority;
            bestSoFar = [[left, top], note];
        }
    }

    return bestSoFar;
}

function oob(pts, width, height) {
    return pts.some(([x, y]) => x < 0 || y < 0 || x >= width || y >= height);
}

function close(p) {
    return [...p, p[0]];
}

function useViewportProjection(stations = [], containerWidth, containerHeight) {
    return react.useMemo(() => {
        let minX = stations?.[0]?.[1]?.x ?? 0;
        let minY = stations?.[0]?.[1]?.y ?? 0;
        let maxX = minX;
        let maxY = minY;

        for (const [, { x = 0, y = 0 }] of stations) {
            minX = Math.min(minX, x);
            maxX = Math.max(maxX, x);
            minY = Math.min(minY, y);
            maxY = Math.max(maxY, y);
        }

        const minDimension = Math.min(containerWidth, containerHeight) * 0.75;

        const xSpan = (maxX - minX) / minDimension;
        const ySpan = (maxY - minY) / minDimension;

        return [
            ({ x: relWorldX, y: relWorldY }) => {
                return {
                    x: (relWorldX - minX) / xSpan + containerWidth / 2,
                    y: (relWorldY - minY) / ySpan + containerHeight / 2,
                };
            },
            maxX - minX,
            maxY - minY,
        ];
    }, [
        hash([
            containerWidth,
            containerHeight,
            ...stations.map(([, { id, x, y }]) => [id, x, y]),
        ]),
    ]);
}

function useRelativeWorldProjection({ x: camX, y: camY } = {}, zoom) {
    return useCallback(
        ({ x: absWorldX = 0, y: absWorldY = 0 }) => {
            if (camX === undefined || camY === undefined) {
                return undefined;
            }

            return {
                x: (absWorldX - camX) * zoom,
                y: (absWorldY - camY) * zoom,
            };
        },
        [camX, camY, zoom],
    );
}
