import buildArrayNode from './build-array-node.jsx';
import buildArraySummaryNode from './build-array-summary-node.jsx';
import buildDiscriminatorNode from './build-discriminator-node.jsx';
import buildEntitySelectorNode from './build-entity-selector-node.jsx';
import buildStructNode from './build-struct-node.jsx';
import buildTupleNode from './build-tuple-node.jsx';
import deepEqual from 'deep-equal';
import DisplayContext from '../../subcomponents/usernode-editor/DisplayContext.mjs';
import jsonPointer from 'json-pointer';
import OptionalGroup from '../../OptionalGroup.jsx';
import react from 'react';
import safeParseInt from 'parse-int';
import ShipErrorBoundary from '../../ShipErrorBoundary.jsx';
import * as shipUtil from '../../../../utils/utils.mjs';
import validate, { ValidationError } from '../../../../shared/validate.mjs';

import { buildPrettyPrint } from '../../../../hooks/use-pretty-print.jsx';
import { compare as compareObjects } from 'fast-json-patch';
import { Fragment } from 'react';

const integerType = {
    default: (s) => {
        return typeof s?.min === 'number' ? s?.min : s?.[0];
    },
    parse: (s) => (s === '' ? null : safeParseInt(s) ?? s),
    stringify: (i) => (i === null ? '' : `${i}`),
    inputType: 'number',
};

const stringType = {
    default: () => '',
    parse: (x) => x,
    stringify: (x) => x,
    inputType: 'text',
};

function assertProvided(label, thing) {
    if (!thing) {
        throw new Error(`${label} must be provided`);
    }
}

/*
 * Builds a UI for editing a POJO that conforms to a provided spec. Note that
 * because this is a POJO and not a Syncle, array elements have no ids. This is
 * a funadamental limitation since the client cannot generate array ids and:
 * A) sometimes we want to edit things that aren't part of the game model (like
 * sorting/filtering options) and thus exist only on the client, and B) even
 * when editing data synchronized with the server, creating new array elements
 * requires that they exist temporarily without an id while the corresponding
 * network request is in flight. Some heuristic for mapping elements to their
 * after-the-fact ids is thus necessary and this component punts that complexity
 * to its parent.
 *
 * Note that `undefined` and `null` are treated differently by editors built
 * here. `undefined` indicates 'no value yet provided' and is likely to be
 * coerced into some kind of default. `null` indicates 'intentionally left
 * blank' and will be displayed to the user as missing/omitted data.
 */
export function buildTopLevelUi(
    model,
    queryUser,
    flexState,
    spec,
    value,
    vmContext,
    expectedType,
    onChange,
    { debug, extraComponents = {}, lexicalContextStack } = {},
) {
    assertProvided('model', model);
    assertProvided('queryUser', queryUser);
    assertProvided('flexState', flexState);
    assertProvided('spec', spec);

    const [touched, setTouched] = flexState.useState('/touched', new Set());
    const touchedWithOverrides = react.useMemo(
        () => new Set([...touched]),
        [touched],
    );

    const innerOnChange = react.useCallback(
        function innerOnChange(newValue, opts = {}) {
            if (!opts.noTouch) {
                const patches = compareObjects(
                    { root: value },
                    { root: newValue },
                );

                setTouched(
                    (t) =>
                        new Set([
                            ...(t ?? []),
                            ...patches.map(({ path }) =>
                                jsonPointer.compile(
                                    jsonPointer.parse(path).slice(1),
                                ),
                            ),
                        ]),
                );
            }

            onChange(newValue);
        },
        [onChange, setTouched, value],
    );

    const ctx = {
        context: vmContext,
        debug,
        displayContext: new DisplayContext(spec),
        displayHierarchy: [],
        extraComponents,
        flexState,
        lexicalContextStack:
            lexicalContextStack ?? (vmContext ? [[undefined, vmContext]] : []),
        model,
        path: '',
        prettyPrint: buildPrettyPrint(model),
        queryUser,
        touched: touchedWithOverrides,
        validationErrors: {},
    };

    const [c, p, d] = (() => {
        let lastValue;
        let nextValue = value;
        let c, p;

        do {
            lastValue = nextValue;

            try {
                validate(spec, nextValue);
                ctx.validationErrors = {};
            } catch (e) {
                if (!(e instanceof ValidationError)) {
                    shipUtil.unexpectedError(e);
                }

                ctx.validationErrors = { ...e.errors };
            }

            try {
                [c, p, nextValue] = buildUi(
                    spec,
                    nextValue,
                    expectedType,
                    innerOnChange,
                    ctx,
                );
            } catch (e) {
                console.error(e);
                return [
                    // eslint-disable-next-line react/jsx-key
                    <ShipErrorBoundary force={e.message} json={value} />,
                    {},
                    value,
                ];
            }
        } while (!deepEqual(lastValue, nextValue));

        return [c, p, nextValue];
    })();

    return [c, p, d];
}

// Initially tried to build this as React Components with stacked context and
// refs and stuff, but a variety of things were pretty creaky (not least of
// which, we learn things during child-render that affect parent-render, which
// required all sorts of weird refs and state and stuff.)
export function buildUi(spec, value, expectedType, onChange, ctx) {
    const { path, displayHierarchy = [] } = ctx;

    if (typeof spec !== 'object') {
        throw new Error('Bad spec: ' + shipUtil.debugString(spec) + ' ' + path);
    }

    spec = shipUtil.extendSpec(spec, {
        lexicalContextStack: ctx.lexicalContextStack,
        value,
    });

    let result;

    // This can appear alongside other types and overrides how we display them.
    if (spec.$enum) {
        result = buildEnumNode(spec.$enum, value, expectedType, onChange, ctx);
    } else {
        if (spec.$array) {
            const buildFn =
                displayHierarchy.includes('ArrayNode') ||
                displayHierarchy.filter((x) => x === 'Struct').length > 1
                    ? buildArraySummaryNode
                    : buildArrayNode;

            const [, { context: subContext }] = shipUtil.effectiveSpec(
                ctx.context,
                expectedType,
                spec,
                'elements',
            );

            result = buildFn(
                spec.$array,
                value,
                null,
                (newEl) => onChange([...(value ?? []), newEl ?? undefined]),
                onChange,
                {
                    ...ctx,
                    context: subContext,
                },
            );
        } else if (spec.$boolean) {
            result = buildBooleanNode(spec, value, onChange, ctx);
        } else if (spec.$clickDuration) {
            result = buildInputNode(
                spec.$clickDuration,
                value,
                null,
                integerType,
                onChange,
                ctx,
            );
        } else if (spec.$descrim) {
            result = buildDiscriminatorNode(
                spec.$descrim,
                value,
                expectedType,
                onChange,
                ctx,
            );
        } else if (spec.$int) {
            result = buildInputNode(
                spec.$int,
                value,
                null,
                integerType,
                onChange,
                ctx,
            );
        } else if (spec.$ref) {
            if (path === '') {
                result = buildEntitySelectorNode(
                    spec,
                    value,
                    null,
                    onChange,
                    ctx,
                );
            } else {
                result = buildRefNode(spec, value, null, onChange, ctx);
            }
        } else if (spec.$string) {
            result = buildInputNode(
                spec.$string,
                value,
                null,
                stringType,
                onChange,
                ctx,
            );
        } else if (spec.$tuple) {
            result = buildTupleNode(spec, value, null, onChange, ctx);
        } else {
            result = buildStructNode(spec, value, null, onChange, ctx);
        }
    }

    if (spec.$label) {
        result[1].label = spec.$label;
    }

    result[0] = <ShipErrorBoundary json={value}>{result[0]}</ShipErrorBoundary>;

    if (spec.$optional && value === undefined) {
        result[2] = undefined;
    }

    if (ctx.extraComponents?.$element) {
        result[0] = (
            <Fragment>
                {ctx.extraComponents?.$element}
                {result[0]}
            </Fragment>
        );
    }

    return result;
}

function buildBooleanNode(spec, value, onChange, ctx) {
    value = !!value;

    return [
        <OptionalGroup
            checked={value}
            key={ctx.path}
            onCheckedChange={() => onChange(!value)}
            title={spec.$label ?? ctx.path}
            variant={ctx.displayHierarchy.length > 1 ? 'embedded' : 'top'}
        />,
        { selfContained: true },
        value,
    ];
}

function buildEnumNode(spec, value, expectedType, onChange, ctx) {
    // We build this into a pretend-$descrim node and then translate back to the
    // enum-mapped values.

    let selected;
    const discrimOptions = {};
    let i = 0;
    for (const { label, value: optionValue } of spec) {
        discrimOptions[`o${i}`] = { $label: label ?? optionValue };

        if (deepEqual(optionValue ?? label, value)) {
            selected = i;
        }
        i++;
    }

    function valueByKey(k) {
        if (typeof k !== 'string') {
            throw new Error('Not a key? ' + shipUtil.debugString(k));
        }

        return spec[k.substring(1)].value ?? spec[k.substring(1)].label;
    }

    function innerOnChange(dnode) {
        const [type] = shipUtil.dnode(dnode);
        onChange(valueByKey(type));
    }

    if (value !== undefined && typeof value !== 'number') {
        throw new Error('Not number: ' + shipUtil.debugString(value));
    }

    const [c, p, innerMassagedValue] = buildDiscriminatorNode(
        discrimOptions,
        selected === undefined
            ? undefined
            : shipUtil.buildDNode(`o${selected}`),
        expectedType,
        innerOnChange,
        ctx,
    );

    const [type] = shipUtil.dnode(innerMassagedValue);
    const massagedValue = valueByKey(type);

    return [c, p, massagedValue];
}

function buildInputNode(spec, value, expectedType, inputConfig, onChange, ctx) {
    if (value === undefined) {
        value = inputConfig.default(spec);

        if (value === undefined) {
            console.log('spec', spec);
            console.log('value', value);
            throw new Error();
        }
    }

    return [
        // eslint-disable-next-line react/jsx-key
        <input
            type='text'
            inputMode={inputConfig.inputType}
            id={ctx.path}
            value={inputConfig.stringify(value) ?? ''}
            placeholder={spec.$placeholder ?? ctx.path}
            onChange={(e) => {
                onChange(inputConfig.parse(e.target.value));
            }}
        />,
        {},
        value,
    ];
}

function buildRefNode(spec, value, expectedType, onChange, ctx) {
    const displayName = value?.$ref
        ? shipUtil.humanDescription(value.$ref.substring(1), ctx.model)
        : '<Pick>';

    async function onClick() {
        const newEntity = await ctx.queryUser(spec, value, {
            lexicalContextStack: ctx.lexicalContextStack,
        });

        if (newEntity !== undefined) {
            onChange(newEntity ?? undefined);
        }
    }

    return [
        <button key='btn' id={ctx.path} onClick={onClick}>
            {displayName}
        </button>,
        {},
        value,
    ];
}
