import clone from 'clone';
import jsonPointer from 'json-pointer';
import Mustache from 'mustache';
import walkSpec from '../shared/walk-spec.mjs';

import {
    coerceToArray,
    debugString,
    dnode,
    humanJobDescription,
    isNil,
    perCorpStationStats,
    set,
    simulateBuy,
    transformObject,
    unmessy,
} from '../shared/utils.mjs';

export * from '../shared/utils.mjs';

export function abbreviate(s) {
    const abbreviations = {
        pressurized: 'prssr',
        standard: 'std',
        storage: 'strg',
        structural: 'strct',
        unpressurized: 'unprssr',
    };

    return s
        .split(' ')
        .map((w) => {
            const lowercaseW = w.toLowerCase();
            const abbreviation = abbreviations[lowercaseW];

            if (abbreviation) {
                if (lowercaseW === w) {
                    return abbreviation;
                }

                return (
                    abbreviation.substring(0, 1).toUpperCase() +
                    abbreviation.substring(1)
                );
            }

            return w;
        })
        .join(' ');
}

export function buildEvent(name, props) {
    const e = new Event(name);
    e.details = props;
    Object.assign(e, props);
    return e;
}

export function constrainedDiscrim(spec, context, expectedType) {
    if (spec.$descrim) {
        throw new Error('You probably meant to pass me the $descrim itself');
    }

    const constrainedDiscrimInheritence = {
        shipOrderClaim: ['shipOrder'],
        shipOrderTravel: ['shipOrder'],
    };

    function buildContext(context) {
        context = coerceToArray(context, null);

        if (context === null) {
            return null;
        }

        const contextSet = new Set(context);
        let oldSize = -1;
        while (contextSet.size !== oldSize) {
            oldSize = contextSet.size;

            for (const c of contextSet) {
                for (const p of constrainedDiscrimInheritence[c] ?? []) {
                    contextSet.add(p);
                }
            }
        }

        return [...contextSet];
    }

    const oldContext = clone(context);
    context = buildContext(context);

    expectedType = coerceToArray(expectedType, null);

    function correctContext(k, v) {
        if (isNil(context)) {
            return true;
        }
        if (isNil(v.$context)) {
            return true;
        }

        return coerceToArray(v.$context).some((c) => context.includes(c));
    }

    function correctType(k, v) {
        if (isNil(expectedType)) {
            return true;
        }
        if (isNil(v.$type)) {
            return true;
        }

        return coerceToArray(v.$type).some((t) => expectedType.includes(t));
    }

    return transformObject(spec, {
        filter: (k, v) =>
            k.startsWith('$') || (correctContext(k, v) && correctType(k, v)),
    });
}

export function deepTransform(o, fn) {
    if (isNil(o)) {
        return o;
    }

    let result;
    if (Array.isArray(o)) {
        result = o.map((el) => deepTransform(el, fn));
    } else if (typeof o === 'object') {
        result = Object.fromEntries(
            Object.entries(o).map(([k, v]) => [k, deepTransform(v, fn)]),
        );
    } else {
        result = fn(o);
    }

    return result;
}

export function descrim(o, d) {
    if (typeof o !== 'object' || o === null) {
        if (d) {
            return [d];
        }

        throw new Error('Expected an object. Got: ' + JSON.stringify(o));
    }

    const keys = Object.keys(o);
    if (keys.length !== 1) {
        if (d) {
            return [d];
        }

        throw new Error(
            'Expected an object with exactly one key, but got: ' +
                debugString(o),
        );
    }

    return [keys[0], o[keys[0]]];
}

export function discrimCategorize(spec) {
    const categorizeFn = spec.$categorize ?? (() => undefined);

    const categories = {};
    for (const [key, value] of Object.entries(spec).filter(
        ([k]) => !k.startsWith('$'),
    )) {
        let category;
        try {
            category = categorizeFn(value, key) ?? '';
        } catch (e) {
            throw new Error(
                '$categorize failed on ' +
                    debugString(value) +
                    ': ' +
                    e.message,
            );
        }

        if (!categories[category]) {
            categories[category] = [];
        }

        categories[category].push([key, value]);
    }

    return categories;
}

export function extendJsonPath(base, more) {
    if (!Array.isArray(base)) {
        base = jsonPointer.parse(base);
    }

    if (!Array.isArray(more)) {
        more = `${more}`;

        if (more.startsWith('/')) {
            more = jsonPointer.parse(more);
        } else {
            more = [more];
        }
    }

    return jsonPointer.compile([...base, ...more]);
}

// Taken from https://stackoverflow.com/a/9458996
function _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export async function hash(...elements) {
    const encoder = new TextEncoder();
    const data = encoder.encode(JSON.stringify(elements));
    return _arrayBufferToBase64(
        await window.crypto.subtle.digest('SHA-256', data),
    );
}

export function humanDescription(id, model) {
    if (id && typeof id !== 'string' && !id?.$ref) {
        throw new Error('Not an id? ' + debugString(id));
    }

    const data = unmessy(
        id?.$ref
            ? model.get(id.$ref)
            : typeof id === 'string'
              ? model.get(`/${id}`)
              : id,
    );

    if (!data) {
        return '<missing>';
    }

    if (data?.name) {
        return data?.name;
    }

    if (data?.kind?.includes?.('job')) {
        return humanJobDescription(data, model);
    }

    return id;
}

export function humanOrder(order, spec, model) {
    const walker = {
        $array: {
            buildResult(s, v, { subwalk }) {
                return subwalk?.join(', ');
            },
        },
        $boolean: {
            buildResult(s, v) {
                return `${!!v}`;
            },
        },
        $clickDuration: {
            buildResult(s, v) {
                return `${v} clicks`;
            },
        },
        $descrim: {
            buildResult(s, v, { subwalk = { $v: '' } }) {
                return subwalk.$v;
            },
        },
        $int: {},
        $ref: {
            buildResult(s, v) {
                return humanDescription(v, model);
            },
        },
        $string: {},
        $struct: {
            buildResult(s, v, { subwalk }) {
                if (!s.$humanize) {
                    return '';
                }

                // React is going to handle escaping for us, so this is just
                // going to wind up double-excaping.
                const template = s.$humanize.en.replace(
                    /\{\{([^\}]*)\}\}/g,
                    '{{{$1}}}',
                );
                return Mustache.render(template, subwalk);
            },
        },
        $tuple: {
            buildResult(s, v, { subwalk = [] }) {
                return `<${subwalk.join(', ')}>`;
            },
        },
    };

    return walkSpec(walker, spec, order);
}

export function humanQuantity(
    n,
    unit,
    { forceSign = false, missing = 'N/A', round = false } = {},
) {
    if (n === undefined || isNaN(n)) {
        return missing;
    }

    if (unit === undefined) {
        return sign(n, forceSign) + n;
    }

    let [scaledUnit, ...perUnits] = unit.split('/');

    return (
        sign(n, forceSign) +
        [humanUnitful(n, scaledUnit, { round }), ...perUnits].join('/')
    );
}

export function humanAbsoluteEffect(n, p) {
    const unit = synthesizedPropUnits(p);

    if (!unit) {
        return sign(n, true) + n;
    }

    return humanQuantity(n, unit, { forceSign: true });
}

export function humanFactorEffect(n) {
    return sign(n, true) + n * 100 + '%';
}

export const resources = {
    cabinModularity: {
        friendlyName: 'Cabin Modularity',
    },
    cargoThroughput: {
        friendlyName: 'Loading Throughput',
    },
    mass: {
        friendlyName: 'Mass',
    },
    operatingRange: {
        friendlyName: 'Operating Range',
    },
    pressurizedStorage: {
        friendlyName: 'Pressurized Cabin',
    },
    speed: {
        friendlyName: 'Speed',
    },
    structuralHull: {
        friendlyName: 'Structural Hull',
    },
    thrust: {
        friendlyName: 'Thrust',
    },
    unpressurizedStorage: {
        friendlyName: 'Unpressurized Storage',
    },
};

export function humanSynthesizedPropName(
    p,
    { abbreviate: abbrOpt = false } = {},
) {
    let result = resources[p]?.friendlyName ?? p;

    if (abbrOpt) {
        result = abbreviate(result);
    }

    return result;
}

export function humanUnitful(n, unit, { round } = {}) {
    const siPrefix = {
        '-2': 'μ',
        '-1': 'm',
        0: '',
        1: 'k',
        2: 'M',
        3: 'G',
    };

    const siPrefixReverse = Object.fromEntries(
        Object.entries(siPrefix).map(([k, v]) => [v, k]),
    );
    siPrefixReverse['\\'] = 0;

    if (n === undefined || n === null || isNaN(n)) {
        return `Undefined ${unit}`;
    }

    unit = unit.replace('2', '²').replace('3', '³');
    const dimension = unit.endsWith('³') ? 3 : unit.endsWith('²') ? 2 : 1;

    if (dimension !== 1) {
        // We don't bother playing with non-linear dimensions. We'll just promise to
        // keep our values in the right range to begin with. :)
        return `${n} ${unit}`;
    }

    const [baseUnit, startScale] =
        unit.length === 1
            ? [unit, 0]
            : [unit.substring(1), siPrefixReverse[unit.substring(0, 1)]];

    if (n === 0) {
        return `${n} ${baseUnit}`;
    }

    let shifts = startScale;

    if (unit.charAt(0) !== '\\') {
        while (Math.trunc(n) === 0) {
            shifts--;
            n *= 1000;
        }

        while (Math.abs(Math.trunc(n)) >= 1000) {
            shifts++;
            n /= 1000;
        }
    }

    const formattedN = new Intl.NumberFormat(
        undefined,
        round
            ? {
                  maximumFractionDigits: 2,
              }
            : {
                  maximumFractionDigits: 21,
              },
    ).format(n);

    return `${formattedN} ${siPrefix[shifts]}${baseUnit}`;
}

function decimalPartCounts(n) {
    const iPart = Math.trunc(n);
    const dPart = n - iPart;

    return [`${iPart}`.length, `${dPart}`.length];
}

export function inductive(fn) {
    let o;

    const marker = Symbol('point marker');

    const lhCache = new Map();
    function lazyHandler(path = '') {
        return {
            get(target, field) {
                if (field === marker) {
                    return true;
                }

                if (typeof field === 'symbol') {
                    return target[field];
                }

                const result =
                    lhCache.get(`${path}/${field}`) ??
                    new Proxy(
                        () => jsonPointer.get(o, `${path}/${field}`),
                        lazyHandler(`${path}/${field}`),
                    );

                lhCache.set(`${path}/${field}`, result);

                return result;
            },
        };
    }

    const rhCache = new WeakMap();
    const readHandler = {
        get(target, field) {
            const original = target[field];

            if (original?.[marker]) {
                return original();
            }

            if (typeof original === 'object') {
                const result =
                    rhCache.get(target[field]) ??
                    new Proxy(target[field], readHandler);

                rhCache.set(target[field], result);

                return result;
            }

            return original;
        },
    };

    o = new Proxy(fn(new Proxy({}, lazyHandler())), readHandler);

    return o;
}

export function initializeName(n) {
    return n
        .split(' ')
        .map(([i]) => i)
        .join('');
}

export function isTouchDevice() {
    return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    );
}

export function jsonOrNothing(raw, fallback = null) {
    let result;

    if (typeof raw === 'string') {
        try {
            result = JSON.parse(raw);
        } catch (e) {
            result = fallback;
        }
    } else {
        throw new Error('Not a string: ' + debugString(raw));
    }

    return result;
}

export function maybeField(src, field) {
    return src[field] ? { [field]: src[field] } : undefined;
}

export function normalizeJsonPath(src, path) {
    const normalPath = [];

    if (typeof path === 'string') {
        path = jsonPointer.parse(path);
    }

    let current = src;
    let previous;

    let lastKey;
    for (let key of path) {
        if (key === '-' && Array.isArray(current)) {
            key = current.length;
        }

        normalPath.push(key);

        current = current?.[key];
    }

    return jsonPointer.compile(normalPath);
}

export function purchaseBlocker(model, stationId, corpId, orderId) {
    const corpData = model.get(`/${corpId}`);
    const orderData = model.get(`/${stationId}/market/${orderId}`);

    if (!orderData) {
        return 'That market order no longer exists.';
    }

    if ((corpData.credits ?? 0) < (orderData.price ?? 0)) {
        return 'Not enough credits.';
    }

    try {
        simulateBuy(model, stationId, orderId, corpId);
    } catch (e) {
        if (e.code !== 'INSUFFICIENT_RESOURCE') {
            unexpectedError(e);
        }

        return `Not enough ${humanSynthesizedPropName(
            e.resource,
        ).toLowerCase()}.`;
    }
}

export function specDiscrim(spec, data, context, expectedType) {
    if (typeof data !== 'object' && data !== undefined) {
        throw new Error('Data not an object: ' + debugString(data));
    }

    spec = constrainedDiscrim(spec, context, expectedType);

    const dataKeys = Object.keys(data ?? {});

    let type, subdata;
    if (dataKeys.length !== 0) {
        [type, subdata] = dnode(data);
    } else {
        // Let's limit our spec to just the highest priority-as-default options.
        let highestPriority = 0;
        for (const { $priorityToBeDefault = 0 } of Object.entries(spec)
            .filter(([k]) => !k.startsWith('$'))
            .map(([k, v]) => v)) {
            if ($priorityToBeDefault > highestPriority) {
                highestPriority = $priorityToBeDefault;
            }
        }

        spec = Object.fromEntries(
            Object.entries(spec).filter(
                ([k, { $priorityToBeDefault = 0 }]) =>
                    k.startsWith('$') ||
                    $priorityToBeDefault === highestPriority,
            ),
        );

        // If available, we should default to a type from the "default"
        // category.
        const categories = discrimCategorize(spec);

        if ((categories['']?.length ?? 0) > 0) {
            type = categories[''][0][0];
        } else {
            type = Object.keys(spec).filter((k) => !k.startsWith('$'))[0];
        }

        if (!type) {
            throw new Error(
                'No available default for spec: ' + debugString(spec),
            );
        }
    }

    return [type, subdata];
}

export function stripArrayElementIds(root) {
    if (Array.isArray(root)) {
        return root.map(({ value }) => stripArrayElementIds(value));
    }

    if (typeof root === 'object' && root !== null) {
        return Object.fromEntries(
            Object.entries(root).map(([k, v]) => [k, stripArrayElementIds(v)]),
        );
    }

    return root;
}

export function synthesizedPropUnits(prop) {
    return {
        cabinModularity: '\\points',
        cargoThroughput: '\\pallets/hr',
        mass: 'Mg',
        operatingRange: 'Mm',
        pressurizedStorage: 'm³',
        speed: 'Mm/hr',
        standardStorage: 'm³',
        structuralHull: 'm²',
        thrust: 'MN',
        unpressurizedStorage: 'm³',
    }[prop];
}

export function top(a) {
    return a[a.length - 1];
}

export function withoutAnnotations(o) {
    return typeof o === 'object'
        ? Object.fromEntries(
              Object.entries(o).filter(([k]) => !k.startsWith('$')),
          )
        : o;
}

export function withoutKey(o, key) {
    return Object.fromEntries(Object.entries(o).filter(([k, v]) => k !== key));
}

// ### Auxiliary functions ###

function sign(n, forceSign) {
    return n >= 0 ? (forceSign ? '+' : '') : '';
}

function signedFactor(n, forceSign) {
    return sign(n, forceSign) + n * 100 + '%';
}
