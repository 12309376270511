import * as appContexts from './app-contexts.jsx';
import GameTheme from './components/fragments/GameTheme.jsx';
import GameUiProvider from './components/fragments/GameUiProvider.jsx';
import react from 'react';
import * as reactRouter from 'react-router-dom';
import routerBuilder from './router-builder.jsx';
import * as Sentry from '@sentry/react';
import useBody from './hooks/use-body.jsx';
import useGameServer from './hooks/use-game-server.jsx';

import { Case, Default, Switch } from 'react-if';
import { generateId } from './shared/model.mjs';
import { useEffect, useMemo, useState } from 'react';

import './App.css';

function useBackend() {
    const params = new URLSearchParams(document.location.search);
    const [pinnedBackendUrl, setPinnedBackendUrl] = useState(
        params.get('backendUrl'),
    );

    const [serverConfiguredBackendUrl, serverConfiguredBackendUrlError] =
        useBody('/backendUrl');

    const backend = useGameServer();

    useEffect(() => {
        if (serverConfiguredBackendUrl && !pinnedBackendUrl) {
            setPinnedBackendUrl(serverConfiguredBackendUrl);
        }

        if (pinnedBackendUrl ?? serverConfiguredBackendUrl) {
            console.log(
                'targetting',
                pinnedBackendUrl ?? serverConfiguredBackendUrl,
            );
            backend.retargetBackend(
                pinnedBackendUrl ?? serverConfiguredBackendUrl,
            );
        }
    }, [
        backend,
        serverConfiguredBackendUrl,
        pinnedBackendUrl,
        setPinnedBackendUrl,
    ]);

    return [backend, serverConfiguredBackendUrlError];
}

function App({ dependencies, createBrowserRouter }) {
    const rootId = react.useMemo(() => generateId('app'), []);

    const router = react.useMemo(
        () => routerBuilder(createBrowserRouter),
        [createBrowserRouter],
    );

    const dependenciesContext = useMemo(
        () => ({ rootId, useBackend, ...dependencies }),
        [dependencies, rootId],
    );

    const [gameState, gsError] = dependenciesContext.useBackend();

    return (
        <appContexts.DependenciesContext.Provider value={dependenciesContext}>
            <Switch>
                <Case condition={gsError}>
                    <p>{gsError}</p>
                </Case>
                <Case condition={!gameState?.model}>
                    <p>Loading...</p>
                </Case>
                <Default>
                    <appContexts.GameStateContext.Provider value={gameState}>
                        <div id={rootId}>
                            <App2 router={router} />
                        </div>
                    </appContexts.GameStateContext.Provider>
                </Default>
            </Switch>
        </appContexts.DependenciesContext.Provider>
    );
}

function App2({ router }) {
    return (
        <GameUiProvider>
            <GameTheme>
                <reactRouter.RouterProvider router={router} />
            </GameTheme>
        </GameUiProvider>
    );
}

const AppWithTelemetry = import.meta.env.DEV ? App : Sentry.withProfiler(App);

export default AppWithTelemetry;
