import clone from 'clone';
import jsonPointer from 'json-pointer';

export default class Mask {
    isSet = {};
    values = {};

    clear(prefix = '') {
        if (prefix === '') {
            this.isSet = {};
            this.values = {};
        } else {
            jsonPointer.remove(this.isSet, prefix);
            jsonPointer.remove(this.values, prefix);
        }
    }

    copy() {
        const result = new Mask();
        result.isSet = clone(this.isSet);
        result.values = clone(this.values);
        return result;
    }

    dict() {
        if (this.isSet === true) {
            return { '': this.values };
        }

        const result = {};
        for (const key of Object.keys(jsonPointer.dict(this.isSet))) {
            result[key] = jsonPointer.get(this.values, key);
        }
        return result;
    }

    mark(path, value) {
        const components =
            typeof path === 'string' ? jsonPointer.parse(path) : [...path];

        if (components.length === 0) {
            const newVal = [];
            this.values = accumVals(this.isSet, this.values, newVal);
            newVal.push(value);

            this.isSet = true;

            return;
        }

        let lastSetNode;
        let lastValNode;
        let lastKey;
        let setNode = this.isSet;
        let valNode = this.values;
        while (
            components.length > 0 &&
            setNode !== true &&
            setNode[components[0]]
        ) {
            lastSetNode = setNode;
            lastValNode = valNode;

            let curKey = components[0];
            if (curKey === '-') {
                let highest = 0;
                for (const key of Object.keys(valNode[0])) {
                    if (/^\d+$/.test(key)) {
                        const keyInt = Number.parseInt(key);
                        if (keyInt > highest) {
                            highest = keyInt;
                        }
                    }
                }
                curKey = `${highest}`;
            }

            lastKey = components[0];

            setNode = setNode[components[0]];
            valNode = valNode[components[0]];

            components.shift();
        }

        if (setNode === true) {
            valNode.push(value);
        } else {
            if (components.length === 0) {
                const newVal = [];
                accumVals(lastSetNode, lastValNode, newVal);
                newVal.push(value);

                lastSetNode[lastKey] = true;
                lastValNode[lastKey] = newVal;
            } else {
                set(setNode, components, true);
                set(valNode, components, [value]);
            }
        }
    }
}

// jsonPointer creates array nodes for integer indexes, which messes with our
// bookkeeping.
function set(node, path, value) {
    let parent = node;
    let lastKey;

    path = [...path];
    while (path.length > 0) {
        const next = path.shift();
        if (!node[next]) {
            node[next] = {};
        }

        parent = node;
        lastKey = next;

        node = node[next];
    }

    parent[lastKey] = value;
}

function accumVals(setNode, valNode, accum) {
    if (setNode === true) {
        for (const v of valNode) {
            accum.push(v);
        }
    } else {
        for (const key of Object.keys(setNode)) {
            accumVals(setNode[key], valNode[key], accum);
        }
    }
}
