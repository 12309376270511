import arithmetic from './arithmetic.mjs';
import clone from 'clone';
import jsonPointer from 'json-pointer';
import resourceBoundSubsystem from './resource-bound-subsystem.mjs';
import tags from './tags.mjs';

import { replacementHierarchy } from './resource-bound-subsystem.mjs';

// # Constants.

// Note that "hour" here expresses design-intent and not wall time. I.e., if you
// wanted to run the game at 2x speed or faster (as we often do in dev!), an
// "hour" speeds up.
export const CLICKS_PER_HOUR = 12;

// # Functions.

// `o` should be a Model or a Syncle.
export function applyEdit(o, edit) {
    if (edit.op === 'set') {
        o.set(edit.key, edit.value);
    } else if (edit.op === 'pop') {
        o.pop(edit.key, edit.index);
    } else if (edit.op === 'push') {
        o.push(edit.key, edit.value);
    } else if (edit.op === 'reorder') {
        const existingArray = clone(o.get(edit.key));
        if (Array.isArray(existingArray)) {
            let targetIndex = existingArray.findIndex(
                ({ id }) => edit.target === id,
            );

            if (targetIndex === -1) {
                throw new UserError('No such array elemenet: ' + edit.target);
            }

            const pivotIndex =
                edit.pivot === null
                    ? -1
                    : existingArray.findIndex(({ id }) => edit.pivot === id);
            const newIndex =
                pivotIndex +
                (edit.direction === 'before'
                    ? -1
                    : edit.direction === 'after'
                      ? 1
                      : 0);

            existingArray.splice(
                newIndex,
                0,
                clone(existingArray[targetIndex]),
            );

            if (newIndex < targetIndex) {
                targetIndex++;
            }

            existingArray.splice(targetIndex, 1);
            o.set(edit.key, existingArray);
        }
    }
}

export function assert(assertion, msg) {
    if (!assertion) {
        if (typeof msg === 'function') {
            msg = msg();
        }

        throw new Error('Assert failed: ' + msg);
    }
}

function defaultBranchNotFoundFn(value) {
    throw new Error('Unexpected discrimination: ' + debugString(value));
}

export function branch(value, branches, notFoundFn = defaultBranchNotFoundFn) {
    const [pivot, subvalue] = dnode(value);

    let result;
    if (branches[pivot]) {
        result = branches[pivot](subvalue);
    } else {
        result = notFoundFn(value);
    }

    return result;
}

export function capitalize(s) {
    if (typeof s !== 'string') {
        throw new Error('Should be string: ' + debugString(s));
    }

    return s.charAt(0).toUpperCase() + s.substring(1);
}

export function coerceToArray(v, nilVal = []) {
    if (Array.isArray(v)) {
        return v;
    }

    if (v === null || v === undefined) {
        return nilVal;
    }

    return [v];
}

// util.inspect() isn't available in browser and the util-inspect package
// created weird errors. A quick and dirty implementation for useful error
// messages.
export function debugString(v, depth = 2) {
    if (Array.isArray(v)) {
        if (depth === 0) {
            return v.length === 0 ? '[]' : '[...]';
        }

        let result = '[';
        for (const el of v.slice(0, 3)) {
            if (result !== '[') {
                result += ', ';
            }

            result += debugString(el, depth - 1);
        }

        if (v.length > 3) {
            result += ', ...';
        }

        return result + ']';
    }

    if (typeof v === 'object' && v !== null) {
        if (depth === 0) {
            return Object.keys(v).length === 0 ? '{}' : '{...}';
        }

        let result = '{';

        for (const [key, value] of Object.entries(v).slice(0, 3)) {
            if (result !== '{') {
                result += ', ';
            }

            result += key + ': ' + debugString(value, depth - 1);
        }

        if (Object.entries(v).length > 3) {
            result += ', ...';
        }

        return result + '}';
    }

    if (v === undefined) {
        return 'undefined';
    }

    if (typeof v === 'function') {
        const full = '' + v;
        const partial = full.substring(0, 30);

        if (partial.length < full.length) {
            return `<${partial}...>`;
        }

        return `<${partial}>`;
    }

    return JSON.stringify(v);
}

export function buildDNode(type, value) {
    if (value === undefined) {
        return { $d: type };
    }

    if (Array.isArray(value) || typeof value !== 'object' || value?.$d) {
        return {
            $d: type,
            $v: value,
        };
    }

    return {
        $d: type,
        ...value,
    };
}

export function dnode(node, defaultType) {
    if (!node?.$d) {
        if (defaultType !== undefined) {
            return [defaultType];
        }

        throw new Error('Expecting a dnode. Got: ' + debugString(node));
    }

    return [
        node.$d,
        node.$v !== undefined
            ? node.$v
            : Object.fromEntries(
                  Object.entries(node).filter(([k]) => k !== '$d'),
              ),
    ];
}

export function discrim(o, d) {
    if (typeof o !== 'object' || o === null) {
        if (d) {
            return [d];
        }

        throw new Error('Expected an object. Got: ' + JSON.stringify(o));
    }

    const keys = Object.keys(o);
    if (keys.length !== 1) {
        if (d) {
            return [d];
        }

        throw new Error(
            'Expected an object with exactly one key, but got: ' +
                debugString(o),
        );
    }

    return [keys[0], o[keys[0]]];
}

export function effectiveSpec(
    parentContext,
    parentExpectedType,
    parentSpec,
    fieldName,
    lexicalContextStack,
) {
    parentSpec = extendSpec(parentSpec);

    let baseSpec =
        parentSpec[fieldName] ??
        parentSpec.$descrim?.[fieldName] ??
        parentSpec.$array?.elements ??
        parentSpec.$tuple?.[fieldName];

    if (!baseSpec) {
        return [];
    }

    baseSpec = extendSpec(baseSpec);

    const rawExpectedType =
        parentSpec.$expectedTypes?.[fieldName] ??
        parentSpec.$descrim?.$expectedTypes?.[fieldName] ??
        parentSpec.$array?.$expectedTypes?.elements ??
        parentSpec.$tuple?.$expectedTypes?.[fieldName];

    const category = (parentSpec.$descrim?.$categorize ?? (() => {}))(
        baseSpec,
        fieldName,
    );

    const newContext =
        parentSpec.$withContexts?.[fieldName] ??
        parentSpec.$descrim?.$withContexts?.[fieldName] ??
        parentSpec.$array?.$withContexts?.elements ??
        parentSpec.$tuple?.$withContexts?.[fieldName];

    const newLexicalContextStack = newContext
        ? [...(lexicalContextStack ?? []), [undefined, newContext]]
        : lexicalContextStack;

    return [
        baseSpec,
        {
            category,
            context: newContext ?? parentContext,
            expectedType:
                rawExpectedType === 'inherit'
                    ? parentExpectedType
                    : rawExpectedType,
            lexicalContextStack: newLexicalContextStack,
        },
    ];
}

export function extendSpec(x, dynamicContext) {
    x = { ...x };

    while (x?.$extend) {
        const extend = x.$extend;
        delete x.$extend;

        if (typeof extend === 'function') {
            x = extend(x);
        } else {
            x = {
                ...extend,
                ...x,
            };
        }
    }

    while (dynamicContext && x?.$dynamicHints) {
        const fn = x.$dynamicHints.bind(x);
        delete x.$dynamicHints;

        x = fn(x, dynamicContext);
    }

    return x;
}

// jsonPointer.get() won't perform optional lookup through nil values, which we
// sometimes wish it would. Also respects array element ids unless told not to.
export function get(obj, path, defaultValue, { arrayIds = true } = {}) {
    if (typeof path === 'string') {
        path = jsonPointer.parse(path);
    }

    let present;
    while (path.length > 0) {
        let next = path.shift();

        if (
            arrayIds &&
            Array.isArray(obj) &&
            obj.some((el) => el?.id === next)
        ) {
            next = obj.findIndex((el) => el?.id === next);
            present = typeof obj === 'object' && next in obj;
            obj = obj?.[next]?.value;
        } else {
            present = typeof obj === 'object' && next in obj;
            obj = obj?.[next];
        }
    }

    return present ? obj : defaultValue;
}

export function hasKind(d, k) {
    return (unmessy(d)?.kind ?? []).some((v) => v === k);
}

export function humanJobDescription(id, model) {
    const jobDeets = unmessy(typeof id === 'string' ? model.get(`/${id}`) : id);

    switch (jobDeets?.type) {
        case 'delivery': {
            const cargoName =
                model.get(jobDeets.cargo[0][0])?.name ?? '<unknown cargo>';
            const stationName =
                model.get(jobDeets.to)?.name ?? '<unknown station>';
            return `Deliver ${cargoName} to ${stationName}`;
        }
    }

    return id;
}

export function isNil(v) {
    return v === undefined || v === null;
}

export function jobReward(j, model) {
    const secondsPerClick = model.get('/config/clickSpeed');
    const defaultSpeedMmPerHr = model.get('/publicMetrics/defaultSpeed') ?? 1;
    const defaultSpeedMmPerClick =
        (defaultSpeedMmPerHr / 60 / 60) * secondsPerClick;
    const bonusFn = model.get('/config/rewardBonusFn');

    const bonus = arithmetic(bonusFn)(j.rewardBonus);
    const from = model.get(j.from);
    const to = model.get(j.to);

    const dX = from.x - to.x;
    const dY = from.y - to.y;
    const dist = Math.sqrt(dX * dX + dY * dY);

    return Math.floor(bonus + (dist / defaultSpeedMmPerClick) * 20);
}

export function logEntryToString(entry, model) {
    if (entry.failingOrder === 'claimJob' && entry.reason === 'tag_violation') {
        try {
            return tags.jobClaimTagToErrorMessage(
                model,
                entry.jobData,
                ...(Object.entries(entry.violations ?? {})[0] ?? []),
            );
        } catch (e) {
            console.error(e);
            return (
                'Did not meet requirements to claim job: ' + entry.violations
            );
        }
    }

    if (entry.text) {
        return entry.text;
    }

    return 'No message.';
}

export function name(model, idOrPath) {
    if (idOrPath?.$ref) {
        idOrPath = idOrPath.$ref;
    }

    if (idOrPath === '' || idOrPath === undefined || idOrPath === null) {
        return '<none>';
    }

    if (typeof idOrPath !== 'string') {
        throw new Error('Not a string: ' + debugString(idOrPath));
    }

    if (!idOrPath.startsWith('/')) {
        idOrPath = `/${idOrPath}`;
    }

    return model.get(idOrPath)?.name ?? idOrPath;
}

export function perCorpStationStats(model, stationId, corpId) {
    return resourceBoundSubsystem(
        model,
        `/${stationId}/corps/${corpId}/modules`,
        'installed',
        model.get(`/${stationId}/groundCorpResources`),
    );
}

export function pick(a) {
    if (a.length === 0) {
        throw new Error('Zero-length array');
    }

    return a[Math.floor(Math.random() * a.length)];
}

export function promise({
    resolve: resolveDecorator = (x, ...args) => x(...args),
    reject: rejectDecorator = (x, ...args) => x(...args),
} = {}) {
    let resolve, reject;
    const promise = new Promise((rs, rj) => {
        resolve = rs;
        reject = rj;
    });
    return [
        promise,
        (...args) => resolveDecorator(resolve, ...args),
        (...args) => rejectDecorator(reject, ...args),
    ];
}

export function refArrayCount(a = [], ref) {
    a = unmessy(a);

    const path = normalizeRef(ref);
    let index = a.findIndex(([{ $ref: k }]) => k === path);

    return index === -1 ? 0 : a[index][1];
}

export function refArrayDec(a = [], ref, amt = 1) {
    return refArrayInc(a, ref, -amt);
}

export function refArrayInc(a = [], ref, amt = 1) {
    const result = [...unmessy(a)];
    const path = normalizeRef(ref);

    let index = result.findIndex(([{ $ref: k }]) => k === path);

    if (index === -1) {
        index = result.length;
        result.push([{ $ref: path }, 0]);
    }

    result[index][1]++;

    return result;
}

export function removeDictLayer(dict, prefix) {
    if (isNil(prefix)) {
        throw new Error('may not be omitted');
    }

    prefix = `${prefix}`;

    if (dict instanceof Set) {
        return new Set(
            [...dict]
                .filter((k) => jsonPointer.parse(k)[0] === prefix)
                .map((k) => {
                    const parsedKey = jsonPointer.parse(k);
                    parsedKey.shift();
                    return jsonPointer.compile(parsedKey);
                }),
        );
    }

    const result = {};
    for (const [k, v] of Object.entries(dict)) {
        const parsedKey = jsonPointer.parse(k);

        if (parsedKey[0] !== prefix) {
            continue;
        }

        parsedKey.shift();

        result[jsonPointer.compile(parsedKey)] = v;
    }

    return result;
}

// jsonPointer.set() won't create intervening structures, which we sometimes
// wish it would.
//
// At one time, this was coded to try to handle "messied" arrays with
// id-keyed-elements, but that was as stupid idea and that implementation here
// is incomplete and wrong. Use `Syncle.mjs` if you want to manipulate data
// with game-model-value-style arrays.
export function set(dest, path, value) {
    if (typeof path === 'string') {
        path = jsonPointer.parse(path);
    }

    if (path.length === 0) {
        return clone(value);
    }

    let current = dest;
    let previous;
    let lastKey;
    for (let key of path) {
        if (isNil(current)) {
            if (/^\d+$/.test(key) || key === '-') {
                if (previous) {
                    previous[lastKey] = [];
                    current = previous[lastKey];
                } else {
                    previous = [];
                    dest = previous;
                    current = previous;
                }
            } else {
                if (previous) {
                    previous[lastKey] = {};
                    current = previous[lastKey];
                } else {
                    previous = {};
                    dest = previous;
                    current = previous;
                }
            }
        }

        if (key === '-' && Array.isArray(current)) {
            key = current.length;
        }

        previous = current;
        lastKey = key;
        current = current[key];
    }

    previous[lastKey] = value;

    return dest;
}

export function simulateBuy(model, stationId, orderId, corpId) {
    const orderData = model.get(`/${stationId}/market/${orderId}`);

    if (!orderData) {
        throw new Error(
            `No such market order: /${stationId}/market/${orderId}`,
        );
    }

    const corpProps = perCorpStationStats(model, stationId, corpId);

    const oldStorageSystem = resourceBoundSubsystem(
        model,
        `/${stationId}/corps/${corpId}/storage`,
        'stored',
        corpProps.availableResources,
    );

    const curCorpStationStorage = unmessy(
        model.get(`/${stationId}/corps/${corpId}/storage`) ?? [],
    );
    const moduleData = unmessy(model.get(orderData.item));

    const proposedCorpStationStorage = [...curCorpStationStorage];
    let moduleIndex = proposedCorpStationStorage.findIndex(
        ([{ $ref: k }]) => k === orderData.item.$ref,
    );

    if (moduleIndex === -1) {
        moduleIndex = proposedCorpStationStorage.length;
        proposedCorpStationStorage.push([orderData.item, 0]);
    }

    proposedCorpStationStorage[moduleIndex][1]++;

    const newStorageSystem = resourceBoundSubsystem(
        model,
        proposedCorpStationStorage,
        'stored',
        corpProps.availableResources,
    );

    const oldViolations = oldStorageSystem.unsupportedModules ?? [];

    // Look for any new violation (because of resource substitution, adding a
    // module may cause an existing module to begin to be in violation.)
    for (const v of newStorageSystem.unsupportedModules ?? []) {
        const corresponding = oldViolations.find(([k]) => k === v[0]);
        const oldViolatedResources = corresponding?.[1] ?? [];

        for (const newViolatedResource of v[1]) {
            if (!oldViolatedResources.includes(newViolatedResource)) {
                let reportedResource = newViolatedResource;

                // The violation may be in a substituted resource of another
                // module, which the query module doesn't actually require,
                // which is probably confusing to the user. "Walk up" the
                // contested resource to a resource of the new module that
                // actually triggered the issue;
                while (
                    reportedResource &&
                    !moduleData.requirements[reportedResource]
                ) {
                    reportedResource = replacementHierarchy[reportedResource];
                }

                reportedResource = reportedResource ?? newViolatedResource;

                const e = new Error(`Insufficient ${reportedResource}.`);
                e.code = 'INSUFFICIENT_RESOURCE';
                e.resource = reportedResource;
                throw e;
            }
        }
    }
}

export function synthesizePerCorpStationProps(stationId, corpId, model) {
    const stationData = unmessy(model.get(`/${stationId}`));

    if (!stationData) {
        return {};
    }

    const corpResources = perCorpStationStats(model, stationId, corpId);

    return corpResources.availableResources;
}

export function synthesizeShipProps(shipData, model) {
    shipData =
        typeof shipData === 'string'
            ? model.get(`/${shipData}`)
            : shipData?.$ref
              ? model.get(shipData)
              : shipData;

    if (!shipData) {
        return {};
    }

    shipData = unmessy(shipData);

    const shipResources = resourceBoundSubsystem(
        model,
        shipData.modules,
        'installed',
        shipData.groundShipResources,
    );

    return shipResources.availableResources;
}

export function transformObject(
    o,
    { transform = (k, v) => [k, v], filter = () => true } = {},
) {
    if (isNil(o)) {
        return o;
    }

    return Object.fromEntries(
        Object.entries(o)
            .filter(([k, v]) => filter(k, v))
            .map(([k, v]) => transform(k, v)),
    );
}

export class UnexpectedError extends Error {
    constructor(cause, msg = cause.message) {
        super(msg);
        this.cause = cause;
    }
}

export function unexpectedError(e, msg) {
    throw new UnexpectedError(e, msg);
}

export function unmessy(a) {
    if (Array.isArray(a)) {
        return unmessyArray(a);
    }

    if (a === null) {
        return null;
    }

    if (typeof a === 'object') {
        /*const [type, data] = dnode(a, null);

        if (type !== null) {
            return {
                $d: type,
                $v: data
            };
        }*/

        return Object.fromEntries(
            Object.entries(a).map(([k, v]) => [k, unmessy(v)]),
        );
    }

    return a;
}

export function unmessyArray(a) {
    if (a === undefined) {
        a = [];
    }

    if (!Array.isArray(a)) {
        throw new Error('Expecting array. Got: ' + util.format(a));
    }

    return a.map((el) => {
        if (typeof el === 'object' && el !== null && 'id' in el) {
            return unmessy(el.value);
        }

        return unmessy(el);
    });
}

function normalizeRef(ref) {
    return typeof ref === 'string'
        ? ref.startsWith('/')
            ? ref
            : `/${ref}`
        : ref?.$ref;
}
