import AugmentedWebsocket from './AugmentedWebsocket.mjs';
import Model from '../shared/model.mjs';
import ms from 'ms';
import serverMessageHandlers from './server-message-handler.mjs';
import * as shipUtil from './utils.mjs';

import { stripZeroValues } from '../shared/model.mjs';

export default class ServerModel extends EventTarget {
    static async fromAnonymous(url, createAccountOpts) {
        console.log('fromAnonymous', url);

        const existingAccountId = localStorage.getItem('anonymousAccountId');
        const existingAccountSecret = localStorage.getItem(
            'anonymousAccountSecret',
        );

        const augWs = new AugmentedWebsocket(new WebSocket(url));

        const result = new ServerModel({
            augWs,
            url,
        });

        let loggedAccountId;
        let newSecret;

        if (existingAccountId && existingAccountSecret) {
            console.log('Trying to use existing account');
            try {
                ({ id: loggedAccountId } = await augWs.sendRpc({
                    type: 'AuthenticateAnonymousAccount',
                    accountId: existingAccountId,
                    secret: existingAccountSecret,
                }));
            } catch (e) {
                console.error(e);
                // No problem, we'll create a new account.
            }
        }

        if (!loggedAccountId) {
            console.log('No dice, new account');
            ({ id: loggedAccountId, secret: newSecret } = await augWs.sendRpc({
                type: 'CreateAnonymousAccount',
                opts: createAccountOpts,
            }));
        }

        if (newSecret) {
            console.log('Setting account info...');
            localStorage.setItem('anonymousAccountId', loggedAccountId);
            localStorage.setItem('anonymousAccountSecret', newSecret);
        }

        result.accountId = loggedAccountId;
        result.createAccountOpts = createAccountOpts;

        return result;
    }

    static async fromOidcCode(url, createAccountOpts, code, state, oidcConfig) {
        const augWs = new AugmentedWebsocket(new WebSocket(url));

        const result = new ServerModel({
            augWs,
            url,
        });

        result.oidcAuthenticationCodeHash = await ServerModel.hashCode(code);

        const { id: accountId, permissions } = await result.augWs.sendRpc({
            type: 'CreateOrAuthenticateByOidcAuthCode',
            code,
            state,
            redirectUri: `${window.location.origin}${window.location.pathname}`,
            opts: createAccountOpts ?? {},
        });

        result.accountId = accountId;
        result.createAccountOpts = createAccountOpts;
        result.oidcConfig = oidcConfig;

        return result;
    }

    static async hashCode(code) {
        return await shipUtil.hash('oidc code', code);
    }

    constructor({ accountId, augWs, oidcAuthenticationCodeHash, url }) {
        super();

        console.log('ServerModel', url);

        this.accountId = accountId;
        this.augWs = augWs;
        this.baseModel = new Model({});
        this.model = new Model({});
        this.oidcAuthenticationCodeHash = oidcAuthenticationCodeHash;
        this.url = url;
        this.optimisticMasks = [];

        const raiseUpdate = () => {
            this.dispatchEvent(
                shipUtil.buildEvent('update', { serverModel: this }),
            );
        };

        const setExtras = (fn) => {
            this.extras = fnize(fn)(this.extras);
            raiseUpdate();
        };

        const setModel = (fn) => {
            this.baseModel = fnize(fn)(this.baseModel);
            this.#buildEffectiveModel();
        };

        const setOptimisticMasks = (fn) => {
            this.optimisticMasks = fnize(fn)(this.optimisticMasks);
            this.#buildEffectiveModel();
        };

        augWs.addEventListener('outofband', ({ payload }) => {
            serverMessageHandlers[payload.type](payload, {
                augWs: this.augWs,
                setExtras,
                setModel,
                setOptimisticMasks,
            });
        });

        augWs.addEventListener('message', ({ payload }) => {
            if (payload.click) {
                this.click = payload.click;
                raiseUpdate();
            }

            if (payload.lastClickAt) {
                this.lastClickAt = payload.lastClickAt;
                raiseUpdate();
            }
        });
    }

    #buildEffectiveModel() {
        if (
            this.baseModel === this.effectiveModelBase &&
            this.optimisticMasks === this.effectiveModelMasks
        ) {
            return;
        }

        const m = this.baseModel.copy();

        for (const { mask } of this.optimisticMasks) {
            for (const [k, v] of Object.entries(mask)) {
                m.set(k, v);
            }
        }

        this.effectiveModelBase = this.baseModel;
        this.effectiveModelMasks = this.optimisticMasks;
        this.model = m;

        this.dispatchEvent(
            shipUtil.buildEvent('update', { serverModel: this }),
        );
    }

    close() {
        this.augWs.close();
    }

    async isSameOidcAuthenticationCode(c) {
        return (
            this.oidcAuthenticationCodeHash === (await ServerModel.hashCode(c))
        );
    }

    async sendRpc(msg, optimisticMask = {}) {
        const maskEntry = {
            createdAt: Date.now(),
        };

        if (msg.type === 'Edit') {
            const mCopy = this.baseModel.copy();
            for (const edit of msg.edits) {
                shipUtil.applyEdit(mCopy, edit);
            }

            maskEntry.mask = mCopy.dict();
        } else {
            maskEntry.mask = shipUtil.transformObject(optimisticMask, {
                transform: (k, v) => [k, stripZeroValues(v)],
            });
        }

        if (Object.entries(maskEntry.mask ?? {}).length > 0) {
            // Update instance so we know to re-cache.
            this.optimisticMasks = [...this.optimisticMasks, maskEntry];

            setTimeout(() => {
                this.optimisticMasks = this.optimisticMasks.filter(
                    (e) => e !== maskEntry,
                );
            }, ms('3s'));

            this.#buildEffectiveModel();
        }

        let result;
        try {
            return await this.augWs.sendRpc(msg);
        } catch (e) {
            console.error(e);
            this.optimisticMasks = this.optimisticMasks.filter(
                (e) => e !== maskEntry,
            );

            const e2 = new Error(e.message);
            e2.cause = e;
            throw e2;
        }
    }
}

function fnize(fn) {
    if (typeof fn !== 'function') {
        const oldFn = fn;
        fn = () => oldFn;
    }

    return fn;
}
