import Link from '../Link.jsx';
import ListItem from '../ListItem.jsx';
import ProgressBar from '../ProgressBar.jsx';
import * as shipUtil from '../../../utils/utils.mjs';

import { css } from '@emotion/react';
import { GiSpaceship } from 'react-icons/gi';
import { useContext } from 'react';
import { GameStateContext } from '../../../app-contexts.jsx';
import { currentActionText } from '../../pages/utils/Ship/ship.jsx';
import { travelTimeClicks } from '../../../shared/simulate-order.mjs';
import { Unknowns } from '../../../shared/resource-bound-subsystem.mjs';

export default function ShipTile({ className = '', data, id }) {
    data.isASyncle();
    data = data.toPojo();

    const gState = useContext(GameStateContext);
    const [auxText, completed, outOf] = renderAuxText(data, gState);

    return (
        <Link className={className} to={`/ships/${id}`}>
            <ListItem
                icon={<GiSpaceship />}
                title={data.name}
                info={
                    <ProgressBar
                        css={css`
                            font-size: small;
                        `}
                        expectedCompletion={auxText}
                        label={currentActionText(data, gState.model)}
                        progress={completed ? completed / outOf : 0}
                    />
                }
            />
        </Link>
    );
}

function renderAuxText(data, gState) {
    const { click, lastClickAt, model, toWallTime } = gState;

    const clickFraction =
        (Date.now() - lastClickAt) /
        (model.get('/config/clickSpeed', 1) * 1000);

    const formatter = new Intl.DateTimeFormat('en-US', {
        dateStyle: 'short',
        timeStyle: 'short',
    });

    if (data?.location?.type === 'traveling') {
        try {
            const duration = travelTimeClicks(model, data);

            const startClick = data?.location?.startClick;

            if (duration === undefined || startClick === undefined) {
                return [];
            }

            const elapsedClicks = click - startClick;
            const remainingClicks = Math.max(0, duration - elapsedClicks);

            const arriveTime = toWallTime(click + remainingClicks);

            const elapsedMs =
                elapsedClicks * model.get('/config/clickSpeed', 1) * 1000;
            const durationMs =
                duration * model.get('/config/clickSpeed', 1) * 1000;

            return [`${formatter.format(arriveTime)}`, elapsedMs, durationMs];
        } catch (e) {
            if (!(e instanceof Unknowns)) {
                const e2 = new Error(e.message);
                e2.cause = e;
                throw e2;
            }

            return [`Cannot estimate arrival`, 0];
        }
    }

    const [orderType, orderData] = shipUtil.dnode(data?.orders?.[0], 'none');
    switch (orderType) {
        case 'travel': {
            return ['Departing...', 0];
        }
        case 'load':
        case 'unload': {
            try {
                const shipProps = shipUtil.synthesizeShipProps(data, model);
                const throughputPerClick = Math.floor(
                    shipProps.cargoThroughput / shipUtil.CLICKS_PER_HOUR,
                );
                const palettes = cargoCt(orderData);
                const clicks = Math.ceil(palettes / throughputPerClick);

                const arriveTime =
                    Date.now() +
                    clicks * model.get('/config/clickSpeed', 1) * 1000;

                let formattedArriveTime;
                try {
                    formattedArriveTime = formatter.format(arriveTime);
                } catch (e) {
                    return [`Cannot estimate transfer time`, 0];
                }

                return [
                    formattedArriveTime,
                    orderData.doneCt + throughputPerClick * clickFraction,
                    orderData.doneCt + clicks * throughputPerClick,
                ];
            } catch (e) {
                if (!(e instanceof Unknowns)) {
                    const e2 = new Error(e.message);
                    e2.cause = e;
                    throw e2;
                }

                return [`Cannot estimate transfer time`, 0];
            }
        }
    }

    return ['', 0];
}

function cargoCt(data) {
    return (data?.cargo ?? []).reduce((accum, [, ct]) => accum + ct, 0);
}
