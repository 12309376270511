import Model from '../shared/model.mjs';

export default {
    ClientError: (msg) => {
        console.error('out of band ClientError', msg);
    },
    FullModel: (msg, { setExtras, setModel, setOptimisticMasks }) => {
        setModel(new Model(msg.model));
        setExtras((extras) => ({ ...extras, resync: false }));
        setOptimisticMasks([]);
    },
    ModelUpdate: (msg, { augWs, setExtras, setModel }) => {
        setModel((curState) => {
            if (!curState) {
                return;
            }

            const nextModel = curState.shallowCopy();
            try {
                nextModel.applyDiff(msg.diff);
                nextModel.mask.clear();
            } catch (e) {
                console.error('ModelUpdate failed', e);

                setExtras((extras) => ({ ...extras, resync: true }));

                augWs
                    .sendRpc({ type: 'Sync' })
                    .then((response) => {
                        setModel(new Model(response.model));
                        setExtras((extras) => ({ ...extras, resync: false }));
                    })
                    .catch((e) => {
                        console.error('sync error', e);
                    });

                return curState;
            }

            return nextModel;
        });
    },
    Set: (msg, { setModel }) => {
        setModel((curState) => {
            if (!curState) {
                return;
            }

            const nextModel = curState.shallowCopy();
            nextModel.set(msg.key, msg.value);
            nextModel.mask.clear();

            return nextModel;
        });
    },
};
