import clone from 'clone';
import * as shipUtil from '../utils/utils.mjs';

function buildResult(walker, spec, value, ctx) {
    return (walker?.buildResult ?? ((s, v, { subwalk }) => noUndef(subwalk)))(
        spec,
        value,
        ctx,
    );
}

function noUndef(v) {
    if (Array.isArray(v) || typeof v !== 'object' || v === null) {
        return v;
    }

    const result = Object.fromEntries(
        Object.entries(v).filter(([k, v]) => v !== undefined),
    );

    return Object.keys(result).length === 0 ? undefined : result;
}

const justTheValue = (w, s, value) => value;

const annotations = {
    $array: (walker, spec, value, ctx) =>
        value?.map((el) => walkSpec(walker, spec.elements, el, ctx)),
    $boolean: justTheValue,
    $clickDuration: justTheValue,
    $descrim: (walker, spec, value, ctx) => {
        const [descrim, subvalue] = shipUtil.dnode(value, null);

        if (descrim && !spec[descrim]) {
            throw new Error(
                'Value of type ' +
                    descrim +
                    " doesn't meet spec. Value: " +
                    shipUtil.debugString(value) +
                    ', spec: ' +
                    shipUtil.debugString(spec),
            );
        }

        if (descrim) {
            return shipUtil.buildDNode(
                descrim,
                walkSpec(walker, spec[descrim] ?? {}, subvalue, {
                    ...ctx,
                    discrimKey: descrim,
                }),
            );
        }

        return undefined;
    },
    $int: justTheValue,
    $ref: justTheValue,
    $string: justTheValue,
    $tuple: (walker, spec, value, ctx) =>
        value?.map?.((el, i) => walkSpec(walker, spec[i], el, ctx)),
};

export default function walkSpec(walker, spec, value, ctx = {}) {
    if (typeof spec !== 'object' || spec === null) {
        throw new Error('Invalid spec: ' + shipUtil.debugString(spec));
    }

    spec = shipUtil.extendSpec(spec);

    const walkersAnnotations = new Set([...Object.keys(walker)]);
    for (const a of Object.keys(annotations)) {
        walkersAnnotations.delete(a);
        if (!(a in walker)) {
            throw new Error('Walker missing entry for ' + a);
        }
    }

    walkersAnnotations.delete('$struct');
    if (!walker.$struct) {
        throw new Error('Walker missing entry for $struct.');
    }

    if (walkersAnnotations.size > 0) {
        throw new Error('Walker has extra entries: ' + [...walkersAnnotations]);
    }

    let someBuild;
    let result = ctx.result;
    for (const [k, v] of Object.entries(annotations)) {
        if (k in spec) {
            const before = walker?.[k]?.before?.(spec?.[k], value, {
                ...ctx,
                before: ctx.before,
                curResult: result,
            });
            const subwalk = annotations[k](walker, spec?.[k], value, {
                ...ctx,
                before,
                curResult: result,
            });
            const after = walker?.[k]?.after?.(spec?.[k], value, {
                ...ctx,
                curResult: result,
                subwalk,
            });

            someBuild = true;

            result = buildResult(walker?.[k], spec?.[k], value, {
                ...ctx,
                after,
                before,
                curResult: result,
                subwalk,
            });
        }
    }

    let structy = false;
    const relevantCopy = {};
    const fieldNames = Object.keys(spec).filter(([k]) => !k.startsWith('$'));
    for (const k of fieldNames) {
        structy = true;
        relevantCopy[k] = value?.[k];
    }

    if (structy) {
        const before = walker?.$struct?.before?.(spec, relevantCopy, {
            before: ctx.before,
            curResult: result,
        });

        const subwalk = Object.fromEntries(
            fieldNames.map((k) => [
                k,
                walkSpec(walker, spec[k], value?.[k], ctx),
            ]),
        );
        const after = walker?.$struct?.after?.(spec, relevantCopy, {
            curResult: result,
            subwalk,
        });

        someBuild = true;
        result = buildResult(walker.$struct, spec, value, {
            ...ctx,
            after,
            before,
            curResult: result,
            subwalk,
        });
    }

    return someBuild ? result : value;
}
