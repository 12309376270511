import clone from 'clone';
import DynamicCounter from './dynamic-counter.mjs';
import * as shipUtil from './utils.mjs';

export const replacementHierarchy = {
    unpressurizedStorage: 'pressurizedStorage',
};

const syntheticStats = {
    speed: [
        ['thrust', 'mass'],
        (thrust_MN, mass_Mg) => {
            const thrust_N = thrust_MN * 1000000;
            const mass_kg = mass_Mg * 1000;
            const averageBurnS = 15;
            const Mm_per_m = 1000000;

            const force_s2 = thrust_N / mass_kg;
            const metersPerSecond = force_s2 * averageBurnS;
            const metersPerHour = metersPerSecond * 60 * 60;

            return metersPerHour / Mm_per_m;
        },
    ],
};

export class Unknowns extends Error {
    constructor(unknown, className = 'resource') {
        if (unknown.$ref) {
            unknown = unknown.$ref;
        }

        super(`Cannot simulate. Unknown ${className}: ${unknown}`);
    }
}

export default function resourceBoundSubsystem(
    model,
    stacks = [],
    environmentName,
    externalResources = {},
    unsupportedModules = new Map(),
) {
    if (typeof stacks === 'string') {
        stacks = model.get(stacks) ?? [];
    }

    const seenResources = new Set();

    const baseAccumulator = new DynamicCounter();
    const bonusAccumulator = new DynamicCounter();
    const factorAccumulator = new DynamicCounter({}, 1);

    for (const [k, amt] of Object.entries(externalResources)) {
        baseAccumulator[k] = amt;
        seenResources.add(k);
    }

    const requiredResources = new DynamicCounter();

    if (!Array.isArray(stacks)) {
        throw new Error('Weird stacks: ' + shipUtil.debugString(stacks));
    }

    const resolvedStacks = stacks.map((s) => {
        let ref, count;
        if (s.value) {
            ref = s.value[0].value;
            count = s.value[1].value;
        } else {
            [ref, count] = s;
        }

        const module = model.get(ref);

        if (!module) {
            return [ref, null, null, count];
        }

        const requirements = module.requirements ?? {};
        const effects = module.effects ?? {};

        return [ref, requirements, effects, count];
    });

    for (const [ref, mRequirementMap, mEffectMap, count] of resolvedStacks) {
        if (unsupportedModules.has(ref.$ref)) {
            continue;
        }

        if (shipUtil.isNil(mRequirementMap)) {
            throw new Unknowns(ref, 'module');
        }

        const mRequirements = mRequirementMap[environmentName] ?? {};
        const mEffects = mEffectMap[environmentName] ?? [];

        for (const [reqK, reqAmt] of Object.entries(mRequirements)) {
            seenResources.add(reqK);
            requiredResources[reqK] += reqAmt * count;
        }

        for (const {
            value: { base = 0, bonus = 0, factor = 0, property },
        } of mEffects) {
            if (!property) {
                throw new Error(
                    'No property? ' + shipUtil.debugString(mEffects),
                );
            }

            seenResources.add(property);
            baseAccumulator[property] += base * count;
            bonusAccumulator[property] += bonus * count;
            factorAccumulator[property] += factor * count;
        }
    }

    let resourceResult = {};

    const totalResources = {};
    const surplusResources = {};
    for (const k of seenResources) {
        const base = baseAccumulator[k];
        const bonus = bonusAccumulator[k];
        const factor = factorAccumulator[k];

        totalResources[k] = base * factor + bonus;
        surplusResources[k] = totalResources[k] - requiredResources[k];

        resourceResult[k] = {
            total: totalResources[k],
            demand: requiredResources[k],
            surplus: surplusResources[k],
        };
    }

    resourceResult = redistributeResources(resourceResult) ?? resourceResult;

    let originalUnsupportedSize = unsupportedModules.size;
    for (const [ref, mRequirementMap, mEffectMap, count] of resolvedStacks) {
        if (unsupportedModules.has(ref.$ref)) {
            continue;
        }

        const mRequirements = mRequirementMap[environmentName] ?? {};

        for (const [reqK] of Object.entries(mRequirements)) {
            if (resourceResult[reqK].surplus < 0) {
                if (!unsupportedModules.has(ref.$ref)) {
                    unsupportedModules.set(ref.$ref, []);
                }

                unsupportedModules.get(ref.$ref).push(reqK);
            }
        }
    }

    if (unsupportedModules.size !== originalUnsupportedSize) {
        return resourceBoundSubsystem(
            model,
            stacks,
            environmentName,
            externalResources,
            unsupportedModules,
        );
    }

    const availableResources = Object.fromEntries(
        Object.entries(resourceResult)
            .filter(([k, { surplus }]) => surplus > 0)
            .map(([k, { surplus }]) => [k, surplus]),
    );

    for (const [k, [inputs, fn]] of Object.entries(syntheticStats)) {
        if (inputs.every((i) => availableResources[i])) {
            availableResources[k] = fn(
                ...inputs.map((i) => availableResources[i]),
            );
        }
    }

    return {
        availableResources,
        resources: resourceResult,
        unsupportedModules: [...unsupportedModules.entries()],
    };
}

function* unmessyStacks(a) {
    for (const el of a) {
        if (el.value) {
            yield [el.value[0].value, el.value[1].value];
        } else {
            yield el;
        }
    }
}

function redistributeResources(resources) {
    const result = clone(resources);

    for (const k of Object.keys(replacementHierarchy)) {
        if (!redistributeResource(result, k)) {
            return null;
        }
    }

    return result;
}

function redistributeResource(resources, fromK) {
    const from = resources[fromK] ?? { surplus: 0 };
    if (from.surplus < 0 && replacementHierarchy[fromK]) {
        const toK = replacementHierarchy[fromK];

        if (!resources[toK]) {
            resources[toK] = {
                demand: 0,
                surplus: 0,
                total: 0,
            };
        }

        const to = resources[toK];

        to.demand -= from.surplus;
        to.surplus += from.surplus;

        from.demand += from.surplus;
        from.surplus = 0;

        if (!redistributeResource(resources, toK)) {
            return false;
        }
    }

    return from.surplus >= 0;
}

export function simulateAdd(
    model,
    stacksOrPath,
    environmentName,
    externalResources,
    newStacks,
) {
    let simulatedStacks =
        typeof stacksOrPath === 'string'
            ? shipUtil.unmessy(model.get(stacksOrPath) ?? [])
            : stacksOrPath;

    for (const [ref, amt] of newStacks) {
        simulatedStacks = shipUtil.refArrayInc(simulatedStacks, ref, amt);
    }

    return resourceBoundSubsystem(
        model,
        simulatedStacks,
        environmentName,
        externalResources,
    );
}
