import ms from 'ms';
import * as shipUtil from './utils.mjs';

import { generateId } from '../shared/model.mjs';

/*
 * Add queue-messages-until-connection and RPC functionality.
 */
export default class AugmentedWebsocket extends EventTarget {
    #inFlightRequests = {};
    #ws = undefined;

    constructor(ws) {
        super();

        this.#ws = ws;

        if (this.#ws.readyState !== WebSocket.OPEN) {
            this.sendQueue = [];
            this.#ws.addEventListener('open', () => {
                for (const msg of this.sendQueue ?? []) {
                    this.#ws.send(msg);
                }

                delete this.sendQueue;
            });
        }

        const echo = (e) => {
            this.dispatchEvent(shipUtil.buildEvent(e.type, e.details));
        };
        this.#ws.addEventListener('open', echo);
        this.#ws.addEventListener('close', echo);

        this.#ws.addEventListener('message', (event) => {
            const payload = JSON.parse(
                typeof event.data === 'string'
                    ? event.data
                    : event.data.toString('utf8'),
            );

            if (payload.requestId) {
                if (this.#inFlightRequests[payload.requestId]) {
                    const [timeoutHandle, resolve, reject] =
                        this.#inFlightRequests[payload.requestId];
                    delete this.#inFlightRequests[payload.requestId];

                    clearTimeout(timeoutHandle);

                    if (['ClientError', 'ServerError'].includes(payload.type)) {
                        const e = new Error(payload.message);
                        e.details = payload;
                        reject(e);
                    } else if (payload.type === 'Response') {
                        resolve(payload.payload);
                    } else {
                        const e = new Error(
                            'Weird response type: ' + payload.type,
                        );
                        Object.assign(e, payload);
                        reject(e);
                    }
                } else {
                    console.error('dropping response', payload);
                }
            } else {
                this.dispatchEvent(
                    shipUtil.buildEvent('outofband', { payload, ws: this }),
                );
            }

            this.dispatchEvent(
                shipUtil.buildEvent('message', { payload, ws: this }),
            );
        });
    }

    close() {
        console.trace('close');
        this.#ws.close();
    }

    isClosedOrClosing() {
        return [WebSocket.CLOSED, WebSocket.CLOSING].includes(this.readyState);
    }

    get readyState() {
        return this.#ws.readyState;
    }

    sendRpc(msg) {
        if (this.isClosedOrClosing()) {
            console.log(
                'WARNING: Dropping message because connection is closed/closing',
                msg,
            );

            return;
        }

        // Preemptively create an error with a stack at the call site to aid
        // debugging.
        const forwardError = new Error();
        let [promise, resolve, reject] = shipUtil.promise({
            reject(reject, e) {
                forwardError.message = e.message;
                forwardError.cause = e;

                if (e.details) {
                    Object.assign(forwardError, e.details);
                }

                reject(forwardError);
            },
        });

        msg = { ...msg, requestId: generateId('req') };

        const timeoutHandle = setTimeout(() => {
            delete this.#inFlightRequests[msg.requestId];
            reject(new Error('timeout'));
        }, ms('30s'));

        this.#inFlightRequests[msg.requestId] = [
            timeoutHandle,
            resolve,
            reject,
        ];

        if (this.sendQueue) {
            this.sendQueue.push(JSON.stringify(msg));
        } else {
            this.#ws.send(JSON.stringify(msg));
        }

        return promise;
    }
}
