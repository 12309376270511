import deepEqual from 'deep-equal';
import Model from '../shared/model.mjs';
import react from 'react';
import ServerModel from '../utils/ServerModel.mjs';
import * as shipUtil from '../utils/utils.mjs';
import useValue from '../hooks/use-value.jsx';

import { useAsyncMemo } from 'use-async-memo';
import { useMemo } from 'react';

/*
 * Note that game model stuff is a necessary dependency for routing, so this
 * module will by necessity need to be set up _outside_ of React Router's
 * <Router> wrapper, and thus won't have access to any router magic like
 * useNavigation() or useLocation().
 */

function clearSearchParams() {
    const newHashPart = window.location.hash ? `#${window.location.hash}` : '';

    window.history.replaceState(
        window.location.state,
        '',
        `${window.location.pathname}${newHashPart}`,
    );
}

function annotatePromise(pr, then, annotations) {
    pr = pr.then(then).catch(console.error);
    pr.inputs = annotations;
    return pr;
}

/* Establish a new logged-in backend connection, or reuse the existing one. */
function useLoggedInConnection(url, accountCreationOpts, oidcConfig) {
    accountCreationOpts = useValue(accountCreationOpts);
    oidcConfig = useValue(oidcConfig);

    const locationParams = new URLSearchParams(window.location.search);
    const codeParam = locationParams.get('code');
    const stateParam = locationParams.get('state');

    const [backendConnectionPr, setBackendConnectionPr] = react.useState(
        Promise.resolve(),
    );
    const [backendConnection, setBackendConnection] = react.useState();

    const hashedCodeParamPair = useAsyncMemo(
        async () =>
            codeParam
                ? [codeParam, await ServerModel.hashCode(codeParam)]
                : [null, null],
        [codeParam],
    );

    // When the elements of hashedCodeParamPair or any of our connection params
    // (accountCreationOpts, oidcConfig, or url) change, consider redirecting or
    // kicking off a new connection, which will immediately change
    // backendConnectionPr and eventually change backendConnection.
    react.useEffect(() => {
        const [code] = hashedCodeParamPair ?? [null, null];

        if (!url || code !== codeParam) {
            return;
        } else {
            if (code) {
                // We've got a code. We should get a new connection going with
                // it and clear it from the search params.
                setBackendConnectionPr(
                    annotatePromise(
                        ServerModel.fromOidcCode(
                            url,
                            accountCreationOpts,
                            codeParam,
                            stateParam,
                            oidcConfig,
                        ),
                        setBackendConnection,
                        { accountCreationOpts, oidcConfig, url },
                    ),
                );

                clearSearchParams();
            } else {
                // No code. There could be a few reasons why:
                //   1) We're not trying to connect using OIDC.
                //   2) We ARE trying to connect using OIDC but we haven't
                //      gotten a code yet. So we need to redirect to get one.
                //   3) We ARE trying to connect using OIDC and we already got a
                //      code and cleared it. So there's nothing to do.
                if (!oidcConfig) {
                    // Case 1. If we've already got a promise with the current
                    // config, we don't need to do anything else. Otherwise,
                    // build a new anonymous connection.
                    if (
                        !deepEqual(backendConnectionPr?.inputs, {
                            accountCreationOpts,
                            oidcConfig,
                            url,
                        })
                    ) {
                        setBackendConnectionPr(
                            annotatePromise(
                                ServerModel.fromAnonymous(
                                    url,
                                    accountCreationOpts,
                                ),
                                setBackendConnection,
                                { accountCreationOpts, oidcConfig, url },
                            ),
                        );
                    }
                } else {
                    // There's a url and an oidcConfig, but no code

                    if (
                        !deepEqual(backendConnectionPr?.inputs, {
                            accountCreationOpts,
                            oidcConfig,
                            url,
                        })
                    ) {
                        // Case 2. Redirect to get a code.
                        const returnHref = `${window.location.origin}${window.location.pathname}`;
                        const redirectTo = new URL(
                            typeof oidcConfig.login === 'function'
                                ? oidcConfig.login(url)
                                : oidcConfig.login,
                        );

                        redirectTo.search = new URLSearchParams({
                            ...new URLSearchParams(redirectTo.search),

                            redirectUri: returnHref,
                            scope: oidcConfig.scope ?? '',
                        }).toString();

                        window.location.href = redirectTo.href;
                    }
                }
            }
        }
    }, [
        ...(hashedCodeParamPair ?? [null, null]),
        setBackendConnectionPr,
        oidcConfig,
        url,
        accountCreationOpts,
        codeParam,
    ]);

    // When backendConnectionPr changes, install some cleanup logic.
    react.useEffect(() => {
        return () =>
            backendConnectionPr.then((c) => c?.close?.()).catch(console.error);
    }, [backendConnectionPr]);

    return backendConnection;
}

export default function useGameServer({
    accountCreationOpts = {},
    oidcConfig,
} = {}) {
    accountCreationOpts = useValue(accountCreationOpts);
    oidcConfig = useValue(oidcConfig);

    const locationParams = new URLSearchParams(window.location.search);
    const stateQueryParam = JSON.parse(locationParams.get('state') ?? 'null');

    const [backendUrl, setBackendUrl] = react.useState(
        stateQueryParam?.backend ?? locationParams.get('backend'),
    );

    const backendConnection = useLoggedInConnection(
        backendUrl,
        accountCreationOpts,
        oidcConfig,
    );

    const [model, setModel] = react.useState(new Model({}));

    react.useEffect(() => {
        function propagateModel({ serverModel }) {
            setModel(serverModel.model);
        }

        backendConnection?.addEventListener?.('update', propagateModel);

        return () =>
            backendConnection?.removeEventListener?.('update', propagateModel);
    }, [backendConnection, setModel]);

    window.aegisModel = model;

    const terminal = useMemo(
        () => (backendConnection ? gameStateTerminal(backendConnection) : null),
        [backendConnection],
    );

    const toWallTime = useMemo(() => {
        return (qClick) => {
            if (
                backendConnection?.click === undefined ||
                backendConnection?.lastClickAt === undefined
            ) {
                return null;
            }

            const clickDelta = qClick - backendConnection.click;
            const wallEpoch =
                backendConnection.lastClickAt +
                clickDelta *
                    backendConnection.model.get('/config/clickSpeed', 1) *
                    1000;

            return new Date(wallEpoch);
        };
    }, [backendConnection]);

    return {
        backendUrl,
        click: backendConnection?.click,
        lastClickAt: backendConnection?.lastClickAt,
        model: backendConnection?.model,
        retargetBackend(url) {
            setTimeout(() => setBackendUrl(url), 1);
        },
        sendToServer: (msg, optimisticMask) => {
            return backendConnection?.sendRpc(msg, optimisticMask);
        },
        session: { accountId: backendConnection?.accountId },
        terminal,
        toWallTime,

        ...backendConnection?.extras,
    };
}

function gameStateTerminal(serverModel) {
    function doCmd(cmdObj) {
        const [cmd, arg] = shipUtil.dnode(cmdObj);
        switch (cmd) {
            case 'appendOrder': {
                const { ship, order } = arg;
                serverModel.sendRpc({
                    type: 'Edit',
                    edits: [
                        {
                            op: 'set',
                            key: `/${ship}/orders/-`,
                            value: order,
                        },
                    ],
                });

                break;
            }
            case 'changeShipGroup': {
                const { ship, group } = arg;
                serverModel.sendRpc({
                    type: 'Edit',
                    edits: [
                        {
                            op: 'set',
                            key: `/${ship}/group`,
                            value: group,
                        },
                    ],
                });

                break;
            }
            case 'deleteOrder': {
                const { ship, order } = arg;
                serverModel.sendRpc({
                    type: 'Edit',
                    edits: [
                        {
                            op: 'pop',
                            key: `/${ship}/orders`,
                            index: order,
                        },
                    ],
                });

                break;
            }
            default: {
                throw new Error('Unknown terminal command: ' + cmd);
            }
        }
    }

    return doCmd;
}
