import clone from 'clone';
import deepEqual from 'deep-equal';
import react from 'react';
import * as shipUtil from '../../utils/utils.mjs';
import Syncle from '../../shared/Syncle.mjs';
import useFlexState from '../../hooks/use-flex-state.jsx';
import validate from '../../shared/validate.mjs';

import { buildTopLevelUi } from './utils/usernode-editor/build-ui.jsx';
import {
    GameStateContext,
    UsernodeDialogContext,
} from '../../app-contexts.jsx';
import { useCallback } from 'react';
import { stripZeroValues } from '../../shared/model.mjs';
import { css } from '@emotion/react';
import { ValidationError } from '../../shared/validate.mjs';

/*
 * Edit a Syncle, get back appropriate client edit commands whenever edited
 * state passes validation.
 */
export default function LiveUsernodeEditor({
    buildArrayEdits = Syncle.ArrayReplaceStrategy,
    debug,
    expectedType,
    extraComponents,
    flexState: providedFlexState,
    onEdits,
    spec,
    value: externalSyncleValue, // A Syncle.
    vmContext,
}) {
    externalSyncleValue.isASyncle();

    const { model } = react.useContext(GameStateContext);
    const queryUser = react.useContext(UsernodeDialogContext);

    const fallbackFlexState = useFlexState();
    const flexState = providedFlexState ?? fallbackFlexState;

    // We only report changes to our parent when our value passes validation,
    // but our child component (derived from buildTopLevelUi()) might emit
    // values that do not pass validation while the user is in the process of
    // fixing errors. When this variable is non-nil, we use it to track our
    // child's non-validating state and defer emitting changes to our parent.
    // Once our child is in a state that passes validation, we clear this and
    // begin emitting to our parent once again.
    const [pojoOverride, setPojoOverride] = react.useState();

    const pojo = shipUtil.isNil(pojoOverride)
        ? externalSyncleValue.toPojo()
        : pojoOverride;

    // This'll get filled in before onChildChange() is called.
    let massagedData;

    const onChildChange = useCallback(
        function onChildChange(newPojoValue) {
            let errors;

            // UIs provided by buildTopLevelUi() emit the values they are
            // displaying--which could be in an invalid state. Let's check...
            try {
                newPojoValue = validate(spec, newPojoValue);
            } catch (e) {
                if (!(e instanceof ValidationError)) {
                    shipUtil.unexpectedError(e);
                }

                errors = e.errors;
            }

            const massageEdits =
                externalSyncleValue.createEditPatch(massagedData);

            const massagedExternal = new Syncle(externalSyncleValue.value);
            massagedExternal.applyEditPatch(massageEdits);

            if (Object.entries(errors ?? []).length === 0) {
                // No errors! Clear any override...
                setPojoOverride();

                // ...and emit the value up the chain!
                const edits = massagedExternal.createEditPatch(newPojoValue);
                if (edits.length > 0) {
                    onEdits?.(
                        massagedExternal.createEditPatch(newPojoValue),
                        buildArrayEdits,
                    );
                }
            } else {
                // Errors! We need to take over tracking our value until our
                // child once again is reporting a valid data state.
                setPojoOverride(clone(newPojoValue));

                console.error(errors);
            }
        },
        [
            buildArrayEdits,
            externalSyncleValue,
            massagedData,
            onEdits,
            setPojoOverride,
            spec,
        ],
    );

    let unodeComponent;
    [unodeComponent, , massagedData] = buildTopLevelUi(
        model,
        queryUser,
        flexState,
        spec,
        pojo,
        vmContext,
        expectedType,
        onChildChange,
        {
            debug,
            extraComponents,
        },
    );

    // If `flexState` belongs to our parent, then `setValue()` here would
    // trigger a 'can't update another component while rendering this one' error
    // were it inline, so get it not-in-line.
    react.useEffect(() => {
        if (!deepEqual(stripZeroValues(pojo), stripZeroValues(massagedData))) {
            onChildChange(massagedData);
        }
    }, [massagedData, onChildChange, pojo]);

    return (
        <div
            css={css`
                overflow: scroll;
            `}
        >
            {unodeComponent}
        </div>
    );
}
